<div class="bot-page-main">
  <app-header-bot></app-header-bot>
  <div class="bot-container">
    <div class="bot-box-left">
      <div class="botChart">
        <app-chart class="trade_chart baseblock_wrapper"></app-chart>
      </div>
      <div class="bot-history">
        <app-dcaorderhistory></app-dcaorderhistory>
      </div>
    </div>
    <div class="bot-box-midd">
      <app-dcaorderplace *ngIf="isEmpty(posDetails)" orderStep="one"></app-dcaorderplace>
      <app-dcaorderplace *ngIf="!isEmpty(posDetails)" orderStep="three"></app-dcaorderplace>
    </div>
    <div class="bot-box-right">
      <app-dcabotpair></app-dcabotpair>
    </div>
  </div>
</div>
<div class="modal fade primary_modal" id="filter_bot_modal" data-bs-backdrop="static" tabindex="-1"
  aria-labelledby="new_bot_modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">DCA Filter</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="filter_panel">
          <div class="filter_panel_row">
            <label>By exchanges</label>
            <div class="filter_btn_grp">
              <button class="selected">Binance</button>
              <button>Bybit</button>
            </div>
          </div>
        </div>
        <div class="filter_panel_btn">
          <button class="continue border_btn">Clear filter</button>
          <button class="continue">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade primary_modal detail_model" id="table_bot_modal" data-bs-backdrop="static" tabindex="-1"
  aria-labelledby="new_bot_tableLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">History</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="bot-history">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="strategies-tab-pane" role="tabpanel"
              aria-labelledby="strategies-tab" tabindex="0">
              <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="spot-bot-detail-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#spot-bot-detail" role="tab" aria-controls="spot-bot-detail" aria-selected="true">
                    History
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="dca-bot-details-history-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#dca-bot-details-history" role="tab" aria-controls="dca-bot-details-history"
                    aria-selected="false">
                    Open orders
                  </button>
                </li>
              </ul>
              <div class="tab-content mt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="spot-bot-detail" role="tabpanel"
                  aria-labelledby="spot-bot-detail-tab">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date & Time Time</th>
                          <th>Side</th>
                          <th>Action</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Total (USDT)</th>
                          <th>Fee</th>
                          <th>Profit (USDT)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2024-05-23</td>
                          <td><span class="green_txt">Buy</span></td>
                          <td>Base Order</td>
                          <td>3812.39</td>
                          <td>0.0026</td>
                          <td>9.9122</td>
                          <td>O ETH</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="bh-filter-right">
                    <button class="startButton mt-3">Download CSV</button>
                  </div>
                </div>
                <div class="tab-pane fade" id="dca-bot-details-history" role="tabpanel"
                  aria-labelledby="dca-bot-details-history-tab">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date & Time Time</th>
                          <th>Side</th>
                          <th>Action</th>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Total (USDT)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2024-05-23</td>
                          <td><span class="red_txt">Sell</span></td>
                          <td>Take Profit</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>10.1183</td>
                        </tr>
                        <tr>
                          <td>2024-05-23</td>
                          <td><span class="red_txt">Sell</span></td>
                          <td>Take Profit</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>10.1183</td>
                        </tr>
                        <tr>
                          <td>2024-05-23</td>
                          <td><span class="green_txt">Buy</span></td>
                          <td>Take Profit</td>
                          <td>4047.32</td>
                          <td>0.0025 ETH</td>
                          <td>10.1183</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>