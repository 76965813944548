import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { spotSocket } from 'src/app/app.module';
import { fraction, longDecimals, nFormatter, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { BotService } from 'src/app/services/bot.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AuthFacade } from 'src/app/store/auth';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import { PairFacade } from 'src/app/store/botpair';

@Component({
  selector: 'app-dcabotpair',
  templateUrl: './dcabotpair.component.html',
  styleUrls: ['./dcabotpair.component.css']
})
export class DcabotpairComponent {

  toFixedDown = toFixedDown
  truncateDecimals = truncateDecimals
  longDecimals = longDecimals
  fraction = fraction
  nFormatter = nFormatter

  pairs: any = {}
  auth: any = {}
  searchPair: string = ''

  constructor(
    private pairFacade: PairFacade,
    private router: Router,
    private route: ActivatedRoute,
    private service: BotService,
    private authFacade: AuthFacade,
    private walletService: WalletService,
    private tradeAssetFacade: TradeAssetFacade,
    private spotSocket: spotSocket
  ) {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }

  ngOnInit() {
    this.getPairs()
    // this.spotSocket.on('marketPrice', this.fetchMarkPrice)
  }
  // fetchMarkPrice(result: any) {
  //   console.log(result, '---------51')
  // if (result.pairId == this.tradePair._id) {
  // this.tradeData = [...result.data, ...data]
  // }
  // }
  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        this.pairs = pair
      },
      error: (err: any) => { }
    })
  }
  handleSearchPair(event: any) {
    this.searchPair = event.target.value.toUpperCase().replace('/', '')
  }
  changePair(pair: any) {
    const newPair = pair.firstCurrencySymbol + '_' + pair.secondCurrencySymbol
    const activePair = this.route.snapshot.params['tikerRoot']
    if (newPair == activePair) return;

    // this.socketService.emit("unSubscribe", activePair.replace("_", ""));

    this.router.navigateByUrl('/dcabot/' + newPair)
    this.service.setTradePair(pair)
    // this.fetchAssetByCurrency(pair.firstCurrencyId, 'firstCurrency')
    this.fetchAssetByCurrency(pair.secondCurrencyId, 'secondCurrency')

    // this.pairData = pair;
    // this.marketPriceFacade.setSpotMarketPrice(pair)
  }

  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.auth.signedIn) {
      return
    }
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }
}
