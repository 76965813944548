import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, TradeAssetState } from './position-details.state';
import { setPosition, reset } from './position-details.actions';

export const tradeAssetFeatureKey = 'tradeasset';

export const positionDetailsReducers: ActionReducer<TradeAssetState> = createReducer(
    initialState,
    on(setPosition, (state, action) => ({ ...state, positionDetails: action.payload })),
    on(reset, () => initialState)
);