<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox"
          *ngIf="profileDetails.phoneStatus === 'verified' && ['new', 'rejected'].includes(profileDetails.kycStatus) && selectedCountry ==''">
          <h2 class="dashbox_title">Select Country</h2>
          <div class="security_panel">
            <div class="security_panel_row">
              <div class="sec-panel-left w-100">
                <div class="security_panel_content">
                  <p class="full-description">
                    Your address proof and ID proof must be match with your
                    selected country to complete the KYC process successfully.
                  </p>
                  <div class="sign_group sign_group_input mt-4">
                    <label>Select Country</label>
                    <select class="sign_input" (change)="handleChangeCountry($event)">
                      <option value="">Choose Country</option>
                      <option value="IN">India</option>
                      <option *ngFor="let item of countries" [value]="item.countryCode">
                        {{ item.country }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashbox"
          *ngIf="profileDetails.phoneStatus === 'verified' &&  profileDetails.kycCountry !== 'IN' && ['approved', 'pending'].includes(profileDetails.kycStatus)">
          <h2 class="dashbox_title">Your Application Status</h2>
          <div class="security_panel">
            <div class="security_panel_row">
              <div class="sec-panel-left w-100">
                <div class="security_panel_content">
                  <p class="full-description" *ngIf="profileDetails.kycStatus == 'pending'">
                    Your KYC Application is submitted and waiting for Approval.
                  </p>
                  <p class="full-description" *ngIf="profileDetails.kycStatus == 'approved'">
                    Your KYC Application is completed and approved.
                  </p>
                  <button class="withdraw_btn me-2 mt-4 cursor-pointer" (click)="navigate('/dashboard')"
                    type="button">Go to Dashboard</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dashbox"
          *ngIf="profileDetails.phoneStatus === 'verified' && (selectedCountry === 'IN' || (profileDetails.panProof == 'approved' || profileDetails.aadharProof == 'approved'));">
          <h2 class="dashbox_title">Identification</h2>
          <div class="security_panel">
            <div class="security_panel_row">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.3 471.04">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        d="M226.2,0H51.1A51.18,51.18,0,0,0,0,51.1V419.94A51.18,51.18,0,0,0,51.1,471H226.2a51.18,51.18,0,0,0,51.1-51.1V51.1A51.18,51.18,0,0,0,226.2,0ZM102.4,19.66h72.5a5.12,5.12,0,1,1,0,10.24H102.4a5.12,5.12,0,0,1,0-10.24Zm36.25,429.67a10.09,10.09,0,0,1,0-20.17,10.09,10.09,0,0,1,0,20.17Zm-.1-111.41c-55.91,0-101.48-46-101.48-102.4s45.57-102.4,101.48-102.4S240,179.1,240,235.52s-45.57,102.4-101.48,102.4Z" />
                      <path
                        d="M229.79,235.52a92.38,92.38,0,0,1-30.31,68.4,85,85,0,0,0-36.25-22.32,52.47,52.47,0,0,0,27.44-46.08c0-29-23.35-52.53-52.12-52.53s-52.12,23.55-52.12,52.53A52.56,52.56,0,0,0,114,281.6a83.81,83.81,0,0,0-36.25,22.32,92.07,92.07,0,0,1-30.41-68.4c0-50.79,41-92.16,91.24-92.16S229.79,184.73,229.79,235.52Z" />
                      <path
                        d="M180.43,235.52c0,23.24-18.64,42.19-41.68,42.29h-.41c-22.94-.1-41.67-19.05-41.67-42.29s18.84-42.29,41.88-42.29A42.08,42.08,0,0,1,180.43,235.52Z" />
                      <path
                        d="M191.59,310.37a90,90,0,0,1-106,.11,74.24,74.24,0,0,1,52.73-22.43h.41a74.84,74.84,0,0,1,52.84,22.32Z" />
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>KYC Verification</h2>
                  <!-- <p>
                    Maecenas sem elit, lobortis in ultrices id, consectetur non
                    justo. Nunc vulputate quam ante, ut suscipit tellus egestas
                    et.
                  </p> -->
                </div>
              </div>

              <!-- <button class="deposit_btn">KYC Not Submitted</button> -->
            </div>
            <div class="row">
              <div class="col-lg-12">
                <h2 class="dashbox_title mt-4">
                  Aadhaar Verification
                  <span *ngIf="profileDetails.aadharProof == 'approved'" class="sidebar__nav-link mt-3 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.34 428.16">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <path
                            d="M359.89,110.21c-.51-11.27-.51-22-.51-32.77A15.09,15.09,0,0,0,344,62.08c-64,0-112.64-18.43-153.09-57.86a15.8,15.8,0,0,0-21.5,0C129,43.65,80.34,62.08,16.34,62.08A15.09,15.09,0,0,0,1,77.44c0,10.75,0,21.5-.51,32.77-2,107.52-5.12,255,174.59,316.93l5.12,1,5.12-1C364.5,365.18,361.94,218.24,359.89,110.21Zm-192,149a16.51,16.51,0,0,1-10.75,4.1h-.51a14.41,14.41,0,0,1-10.75-5.12L98.26,205.44l23-20.48,37.38,41.47,82.94-78.85,21,22.53Z" />
                        </g>
                      </g>
                    </svg>
                    <span class="text-success">{{ profileDetails.aadharNumber }} Verified</span>
                  </span>
                </h2>

                <form action="javascript:void(0)" class="auth_form row" [formGroup]="kycform"
                  (ngSubmit)="Otpsubmit('otp')" *ngIf="
                    otpstatus == false &&
                    profileDetails.aadharProof != 'approved'
                  ">
                  <div formGroupName="aadharGroup">
                    <div class="col-md-6">
                      <div class="sign_group sign_group_input">
                        <label>Aadhaar Number</label>
                        <input type="text" class="sign_input" placeholder="Enter Aadhaar"
                          formControlName="aadhar_number" />

                        <span class="required"
                          *ngIf="optsubmitted && kycform.get('aadharGroup.aadhar_number')?.errors?.['required']">
                          Aadhaar Number is required
                        </span>

                        <span class="required"
                          *ngIf="optsubmitted && kycform.get('aadharGroup.aadhar_number')?.errors?.['pattern']">Please
                          Must Enter 12 digits Only</span>
                        <span class="required" *ngIf="serverErrors?.aadhar_number">{{ serverErrors.aadhar_number
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <button class="sign_btn" [type]="!requestOtpLoader ? 'submit' : 'button'"
                      [disabled]="requestOtpLoader">
                      <div *ngIf="requestOtpLoader" class="spinner-border spinner-border-sm" role="status"></div>
                      {{ requestOtpLoader ? "Submitting" : "Submit" }}
                    </button>
                  </div>
                </form>

                <form action="javascript:void(0)" class="auth_form row" [formGroup]="kycform"
                  (ngSubmit)="VerifySubmit('verify')" *ngIf="otpstatus == true">
                  <div formGroupName="verifyGroup">
                    <div class="col-md-4">
                      <div class="sign_group sign_group_input">
                        <label>Aadhaar Number</label>
                        <input type="text" class="sign_input" placeholder="Enter Aadhaar"
                          formControlName="aadhar_number" readonly="true" />
                        <span class="required"
                          *ngIf="verifysubmitted && kycform.get('verifyGroup.aadhar_number')?.errors?.['required']">
                          Aadhaar Number is required</span>

                        <span class="required"
                          *ngIf="verifysubmitted &&  kycform.get('verifyGroup.aadhar_number')?.errors?.['pattern']">Please
                          Enter only 12 digits</span>
                        <span class="required" *ngIf="serverErrors?.aadhar_number">{{ serverErrors.aadhar_number
                          }}</span>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="sign_group sign_group_input">
                        <label>Ref Id</label>
                        <input type="text" class="sign_input" placeholder="Enter Ref ID" formControlName="ref_id"
                          readonly="true" />
                        <span class="required"
                          *ngIf="verifysubmitted && kycform.get('verifyGroup.ref_id')?.errors?.['required']">
                          Ref Id is required
                        </span>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="sign_group sign_group_input">
                        <label>OTP</label>
                        <input type="text" class="sign_input" placeholder="Enter OTP" formControlName="otp" />

                        <span class="required"
                          *ngIf="verifysubmitted &&  kycform.get('verifyGroup.otp')?.errors?.['required']">OTP is
                          required</span>

                        <span class="required"
                          *ngIf="verifysubmitted && kycform.get('verifyGroup.otp')?.errors?.['pattern']">Enter 6 digit
                          OTP</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="text-success mb-3" *ngIf="kycBtnLoader; else kycverifybtn">
                      Submit button will appears in {{ kycSeconds }}s
                    </div>
                    <ng-template #kycverifybtn>
                      <button class="sign_btn" type="submit">Submit</button>
                    </ng-template>
                  </div>
                </form>

                <div>&nbsp;</div>
                <h2 class="dashbox_title" *ngIf="profileDetails.aadharProof == 'approved'">
                  Aadhaar & Pan Link Verification
                  <span *ngIf="profileDetails.panProof == 'approved'" class="sidebar__nav-link mt-3 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.34 428.16">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                          <path
                            d="M359.89,110.21c-.51-11.27-.51-22-.51-32.77A15.09,15.09,0,0,0,344,62.08c-64,0-112.64-18.43-153.09-57.86a15.8,15.8,0,0,0-21.5,0C129,43.65,80.34,62.08,16.34,62.08A15.09,15.09,0,0,0,1,77.44c0,10.75,0,21.5-.51,32.77-2,107.52-5.12,255,174.59,316.93l5.12,1,5.12-1C364.5,365.18,361.94,218.24,359.89,110.21Zm-192,149a16.51,16.51,0,0,1-10.75,4.1h-.51a14.41,14.41,0,0,1-10.75-5.12L98.26,205.44l23-20.48,37.38,41.47,82.94-78.85,21,22.53Z" />
                        </g>
                      </g>
                    </svg>
                    <span class="text-success">{{ profileDetails.panNumber }} Verified</span>
                  </span>
                </h2>

                <form novalidate action="javascript:void(0)" class="auth_form row" [formGroup]="kycform"
                  (ngSubmit)="pansubmit('pan')" *ngIf="profileDetails.panProof != 'approved' && profileDetails.aadharProof == 'approved'">
                  <div formGroupName="panGroup">
                    <div class="col-md-4">
                      <div class="sign_group sign_group_input">
                        <label>Aadhaar Number</label>
                        <input type="text" class="sign_input" placeholder="Enter Aadhaar"
                          formControlName="aadhar_number" />

                        <span class="required"
                          *ngIf="pansubmitted && kycform.get('panGroup.aadhar_number')?.errors?.['required']">Aadhaar
                          Number is required</span>

                        <span class="required"
                          *ngIf="pansubmitted && kycform.get('panGroup.aadhar_number')?.errors?.['pattern']">Please
                          Enter only 12 digits</span>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="sign_group sign_group_input">
                        <label>Pan Number</label>
                        <input type="text" class="sign_input" placeholder="Enter Pan" alphaNumeric uppercase
                          formControlName="pan_number" />

                        <span class="required"
                          *ngIf="pansubmitted && kycform.get('panGroup.pan_number')?.errors?.['required']">Pan is
                          required</span>

                        <span class="required"
                          *ngIf="pansubmitted && kycform.get('panGroup.pan_number')?.errors?.['pattern']">Not Allow
                          Space</span>

                        <span class="required" *ngIf="serverErrors?.pan_number">{{ serverErrors.pan_number }}</span>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!-- <button class="sign_btn" type="submit">Submit</button> -->
                      <button class="sign_btn" [type]="!isPanLoading ? 'submit' : 'button'" [disabled]="isPanLoading">
                        <span *ngIf="isPanLoading" class="spinner-grow spinner-grow-sm"></span>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="dashbox" *ngIf=" profileDetails.phoneStatus !=='verified'">
          <div class=" security_panel">
            <div class="security_panel_row" style="border-bottom: 0">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.3 471.04">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        d="M226.2,0H51.1A51.18,51.18,0,0,0,0,51.1V419.94A51.18,51.18,0,0,0,51.1,471H226.2a51.18,51.18,0,0,0,51.1-51.1V51.1A51.18,51.18,0,0,0,226.2,0ZM102.4,19.66h72.5a5.12,5.12,0,1,1,0,10.24H102.4a5.12,5.12,0,0,1,0-10.24Zm36.25,429.67a10.09,10.09,0,0,1,0-20.17,10.09,10.09,0,0,1,0,20.17Zm-.1-111.41c-55.91,0-101.48-46-101.48-102.4s45.57-102.4,101.48-102.4S240,179.1,240,235.52s-45.57,102.4-101.48,102.4Z" />
                      <path
                        d="M229.79,235.52a92.38,92.38,0,0,1-30.31,68.4,85,85,0,0,0-36.25-22.32,52.47,52.47,0,0,0,27.44-46.08c0-29-23.35-52.53-52.12-52.53s-52.12,23.55-52.12,52.53A52.56,52.56,0,0,0,114,281.6a83.81,83.81,0,0,0-36.25,22.32,92.07,92.07,0,0,1-30.41-68.4c0-50.79,41-92.16,91.24-92.16S229.79,184.73,229.79,235.52Z" />
                      <path
                        d="M180.43,235.52c0,23.24-18.64,42.19-41.68,42.29h-.41c-22.94-.1-41.67-19.05-41.67-42.29s18.84-42.29,41.88-42.29A42.08,42.08,0,0,1,180.43,235.52Z" />
                      <path
                        d="M191.59,310.37a90,90,0,0,1-106,.11,74.24,74.24,0,0,1,52.73-22.43h.41a74.84,74.84,0,0,1,52.84,22.32Z" />
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>Verify Phone Number</h2>
                  <p>
                    To ensure a secure and reliable Know Your Customer (KYC)
                    process, we kindly request that you verify your phone
                    number before proceeding.
                  </p>
                </div>
                <button *ngIf="!isOtpSend && seconds === 0" class="deposit_btn cursor-pointer" (click)="resendOtp()"
                  [disabled]="otpLoader">
                  <span *ngIf="otpLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Get OTP
                </button>
                <!-- <ng-template #timer>
                    <span class="deposit_btn" type="button" disabled>{{seconds}} seconds</span>
                  </ng-template> -->
                <!-- <button class="deposit_btn" type="button" (click)="resendOtp()">{{seconds}}Get OTP</button> -->
              </div>
            </div>
            <div class="col-md-6 mt-3" *ngIf="isOtpSend">
              <form [formGroup]="phonegroup">
                <div class="sign_group sign_group_input">
                  <label for="otp_btn">Enter OTP</label>
                  <div class="primary_inp_grp">
                    <input type="text" class="sign_input" placeholder="Enter OTP" formControlName="otp"
                      (input)="handleChangePhoneotp()" />
                    <span *ngIf="seconds === 0; else timer" class="input_info cursor-pointer disabled"
                      (click)="resendOtp()">
                      <span *ngIf="otpLoader" class="spinner-grow spinner-grow-sm" role="status"
                        aria-hidden="true"></span>
                      Resend OTP</span>
                    <ng-template #timer>
                      <span class="input_info" (click)="resendOtp()">Resend in {{ seconds }} seconds</span>
                    </ng-template>
                  </div>
                  <span class="required"
                    *ngIf="phonegroup.get('otp')?.errors?.['required'] && (phonegroup.get('otp')?.touched || phonesubmitted)">
                    OTP field is required
                  </span>
                  <span class="required" *ngIf="phonegroup.get('otp')?.errors?.['pattern']">Please Enter only 6
                    digits
                  </span>
                  <span class="required" *ngIf="phoneOtpserverErrors?.otp">
                    {{ phoneOtpserverErrors.otp }}
                  </span>
                </div>
                <button class="sign_btn" type="button" (click)="verifyPhoneNumber()">
                  Confirm OTP
                </button>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>

<!-- <app-kyc *ngIf="profileDetails.aadharProof == 'approved' && profileDetails.panProof == 'approved' 
&& profileDetails.kycStatus ? 'pending' : 'rejected'"  [selectedCountry]="selectedCountry" ></app-kyc> -->