import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmlServiceService {



  constructor(private http: HttpClient,) { }

  getAmldata(){
    return this.http.get(environment.walletApiUrl + 'api/wallet/v1/get/amldata')
  }

  addAmldata(data:any){

  return this.http.post(environment.walletApiUrl + 'api/wallet/v1/create/amldata', data)

  }

  // updateAmlData(data:any){

  //   return this.http.put(environment.walletApiUrl + 'api/wallet/coinWithdraw', data)


  // }





}
