<div class="bot-order">
  <div class="bot_preview_between">
    <div>
      <div class="bot-order-head">
        <h4>Stop Grid Bot</h4>
        <button class="bot-clear">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <div class="bot_preview_box">
            <div>
              <h6>Cancel all orders and keep</h6>
              <p>
                Open orders will be closed and the current amount in trade
                currency will be held in your balance.
              </p>
            </div>
          </div>
          <div class="bot_preview_box">
            <div>
              <h6>Sell at market price</h6>
              <p>Sell at market price and move the bot to history.</p>
            </div>
            <div class="bot-stop-detail">
              <div>
                <p>Total PNL</p>
                <p>0.00 USDT/<span class="red_txt">0.08%</span></p>
              </div>
            </div>
          </div>

          <div class="bgcolor d-flex align-items-center gap-2">
            <button class="primary_btn"><span>Confirm</span></button>
            <button class="continue"><span>Cancel</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
