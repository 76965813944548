import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-dcabotdetails',
  templateUrl: './dcabotdetails.component.html',
  styleUrls: ['./dcabotdetails.component.css'],
  animations: [
    trigger('contentAnimation', [
    
      transition(':enter', [
        style({ height: 0, opacity: 0 }), 
        animate('.5s ease-out', style({ height: '*', opacity: 1 })) 
      ]),
      
      transition(':leave', [
        animate('.15s ease-in', style({ height: 0, opacity: 0 })) 
      ])
    ])
  ]
})
export class DcabotdetailsComponent {
  isCollapsed = true;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
