<div class="bot-order">
  <div class="bot_preview_between">
    <div>
      <div class="bot-order-head">
        <h4>Stop DCA Bot</h4>
        <button class="bot-clear">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <h3 class="bot_preview_title">Select stop bot conditions</h3>
          <div class="bot_preview_box">
            <div>
              <h6>Sell at market price</h6>
              <p>
                Sell 0.0025 ETH forUSDT at market price and move the bot to
                history.
              </p>
            </div>
            <div class="bot-stop-detail">
              <div>
                <p>Total PNL(Buy&Sell)</p>
                <p>-0.40USDT/</p>
              </div>
              <div>
                <p>Fee</p>
                <p class="red_txt">-4.06%</p>
              </div>
            </div>
          </div>

          <div class="bgcolor d-flex align-items-center gap-2">
            <button class="primary_btn"><span>Confirm</span></button>
            <button class="continue"><span>Cancel</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
