<div class="table-responsive trade_history_table">
    <table class="table primary_table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Pair</th>
                <th>Side</th>
                <th>Price</th>
                <th>Executed</th>
                <th>Trading Fee</th>
                <th>TDS Fee</th>
                <th>Total</th>
                <th>Recived</th>
            </tr>
        </thead>
        <tbody *ngIf="orderData.data.length > 0; else nodata">
            <tr *ngFor="let order of orderData.data">
                <td>{{order.createdAt | date:"YYYY-MM-dd HH:mm"}}</td>
                <td>{{order.firstCurrency}}/{{order.secondCurrency}}</td>
                <td>
                    <span
                        [ngClass]="{ 'red_txt': order.buyorsell == 'sell', 'green_txt': order.buyorsell == 'buy' }">{{order.buyorsell
                        | titlecase}}
                    </span>
                </td>
                <td>
                    <!-- <span [title]="truncateDecimals(order.tradePrice, tradePair.secondFloatDigit)"> -->
                    {{truncateDecimals(order.tradePrice, tradePair.secondFloatDigit)}}
                    <!-- </span> -->
                </td>
                <td>
                    <!-- <span [title]="truncateDecimals(order.tradeQty, tradePair.firstFloatDigit)"> -->
                    {{truncateDecimals(order.tradeQty, tradePair.firstFloatDigit)}}
                    <!-- </span> -->
                </td>
                <td>
                    <!-- <span [title]="truncateDecimals(order.fee, order.buyorsell == 'buy' ? tradePair.firstFloatDigit:
                        tradePair.secondFloatDigit)"> -->
                    {{truncateDecimals(order.fee, 6)}}
                    <!-- </span> -->
                    {{ order.buyorsell == 'buy' ? order.firstCurrency : order.secondCurrency }}
                </td>
                <td>
                    {{truncateDecimals(order.tds_fee,6)}}
                    {{ order.buyorsell == 'buy' ? order.firstCurrency : order.secondCurrency }}
                </td>
                <td>
                    <!-- <span [title]="truncateDecimals(order.tradePrice * order.tradeQty, tradePair.secondFloatDigit)"> -->
                    {{truncateDecimals(order.tradePrice * order.tradeQty,
                    tradePair.secondFloatDigit)}}
                    <!-- </span> -->
                </td>
                <td>
                    {{truncateDecimals((order.tradePrice * order.tradeQty) - order.fee - order.tds_fee,6)}}
                    <!-- {{ order.buyorsell == 'buy' ? order.firstCurrency : order.secondCurrency }} -->
                </td>
            </tr>
        </tbody>
        <ng-template #nodata>
            <tbody>
                <tr>
                    <td colspan="9" style="border-color: transparent;">
                        <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                            <img src="assets/images/no_records_found.png" alt="Notification" class="img-fluid mb-2" />
                            <p>No Records Found!</p>
                        </div>
                        <ng-template #login>
                            <div class="text-center mt-4 mb-4">
                                <img src="assets/images/no_records_found.png" alt="Notification"
                                    class="img-fluid mb-2" />
                                <p>
                                    <a class="blue_txt no-underline" href="signin">Login</a>
                                    or
                                    <a class="blue_txt" href="signup" class="blue_txt no-underline">Register</a>
                                    Now to trade
                                </p>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>