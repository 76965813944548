import { Component } from '@angular/core';

@Component({
  selector: 'app-gridbotpreview',
  templateUrl: './gridbotpreview.component.html',
  styleUrls: ['./gridbotpreview.component.css']
})
export class GridbotpreviewComponent {

}
