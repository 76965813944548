import { Component } from '@angular/core';

@Component({
  selector: 'app-gridbotstop',
  templateUrl: './gridbotstop.component.html',
  styleUrls: ['./gridbotstop.component.css']
})
export class GridbotstopComponent {

}
