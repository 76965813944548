<!-- <div class="noActiveBots">
    <img src="assets/images/noactivebots.svg" class="img-fluid" alt="icon" />
    <p>You don’t have active bots</p>
    <button class="btn startButton">Start NewBot</button>
</div> -->
<div class="bot-order" *ngIf="!showNext">
  <div class="bot-order-head">
    <h4>Create DCA Bot</h4>
    <button class="bot-clear"><img src="assets/images/botclear.svg" alt="close" title="close" /></button>
  </div>
  <div class="bot-order-select">
    <span class="exchange">Exchange</span>
    <div class="drpdwn_box">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
          data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/images/new-logo.svg" class="img-fluid" alt="icon"> Cred
          <!-- <img src="assets/images/binance.svg" class="img-fluid" alt="icon"> Binance -->
        </button>
        <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropSearch">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Exchange Name" />
              <span class="input-group-text"><i class="bi bi-search"></i></span>
            </div>

          </div>
          <ul>
            <li><a class="dropdown-item" role="button" href="javascript:void(0)"><img src="assets/images/binance.svg"
                  class="img-fluid" alt="icon"> Binance</a></li>
            <li><a class="dropdown-item" role="button" href="javascript:void(0)"><img src="assets/images/bybit.svg"
                  class="img-fluid" alt="icon"> Bybit</a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
  <div class="bot-order-select">
    <span class="exchange">Pairs</span>
    <div class="drpdwn_box">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false">{{pairData.firstCurrencySymbol}}/{{pairData.secondCurrencySymbol}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropSearch">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search Pair" (input)="handleSearchPair($event)" />
              <span class="input-group-text"><i class="bi bi-search"></i></span>
            </div>

          </div>
          <div class="pair_table pair_table_chg">
            <div class="pair_table_head">
              <div> Pair</div>
              <div>Price</div>
              <div>24H change</div>
            </div>
            <div class="pair_table_body">
              <div class="pair_table_bodyrow" [ngClass]="{ 'active': i === 0 }"
                *ngFor="let pair of pairs | textFilter: 'tikerRoot': searchPair; let i = index">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <!-- <img src="assets/images/binance.svg" alt="Binance" title="Binance" /> -->
                    <img src={{pair.firstCurrencyImage}} alt="Binance" title="Binance" />
                    <h3 (click)="changePair(pair)">{{pair.firstCurrencySymbol}}<span>/
                        {{pair.secondCurrencySymbol}}</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>{{nFormatter(truncateDecimals(pair.markPrice,
                    pair.secondFloatDigit))}}</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small [ngClass]="(pair.change > 0 ? 'greenTxt' : 'redText')" class="d-flex price-status">
                          {{nFormatter(truncateDecimals(pair.change, pair.secondFloatDigit))}}
                          <span class="pair_uparrow"
                            *ngIf="nFormatter(truncateDecimals(pair.change, pair.secondFloatDigit)) > 0"><img
                              src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                          <span class="pair_downarrow"
                            *ngIf="nFormatter(truncateDecimals(pair.change, pair.secondFloatDigit)) <= 0"><img
                              src="assets/images/downarrow.svg" alt="Down" title="Down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!--  <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"><img src="assets/images/uparrow.svg" alt="Up" title="Up" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"><img src="assets/images/downarrow.svg" alt="down"
                              title="down" /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="createbot_box">
    <p class="bot_preview_title">Strategy<i class="bi bi-info-circle-fill ms-2"></i></p>
    <div class="t_box">Long</div>
    <div class="inputgrp_box">
      <div class="w-100 input-group"><label class="grey">Investment, {{pairData.secondCurrencySymbol}}</label>
        <!-- <input aria-describedby="basic-addon1" name="investment" (input)="handleBSChange($event)"
          formControlName="investment" class="form-control" [appDecimalLimit]="pairData.secondFloatDigit" decimals> -->
        <input type="text" class="form-control" formControlName="investment" (input)="handleTradeChange($event)"
          name="investment" [appDecimalLimit]="pairData.secondFloatDigit" value="{{formValue.investment}}" decimals />
        <span class="grey input-group-text" id="basic-addon1" *ngIf="formValue?.convertPrice > 0">≈ {{
          formValue?.convertPrice }}USD </span>
      </div>
      <span class="required" *ngIf="serverErrors?.investmentAmount">{{
        serverErrors.investmentAmount
        }}</span>
    </div>
  </div>
  <!-- <ngx-slider [value]="sliderVal" (valueChange)="onSliderChange($event)" [options]="options"></ngx-slider> -->
  <div class="accr_box">
    <div class="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#manualAdjustment" aria-expanded="false" aria-controls="manualAdjustment">
            Manual Adjustment
          </button>
        </h2>
        <div id="manualAdjustment" class="accordion-collapse collapse">
          <div class="accordion-body p-0">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Bot Settings
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body px-0">
                    <div class="createbot_box">
                      <p _ngcontent-ng-c446327758="" class="bot_preview_title"><i _ngcontent-ng-c446327758=""
                          class="bi bi-info-circle-fill ms-2"></i> Bot start conditions</p>
                      <div class="inputgrp_box">
                        <div class="w-100 input-group">
                          <label class="grey">Place base order</label>
                          <input aria-label="Username" aria-describedby="basic-addon1" name="investmentAmount"
                            class="form-control" value="Immediately" disabled="true">
                        </div>
                      </div>
                      <div class="inputgrp_box">
                        <div class="w-100 input-group">
                          <label class="grey">Base order amount, {{pairData.secondCurrencySymbol}}</label>
                          <input aria-label="Username" aria-describedby="basic-addon1" name="firstbuyAmount"
                            (input)="handleTradeChange($event)" formControlName="firstbuyAmount"
                            value="{{formValue.firstbuyAmount}}" class="form-control"
                            [appDecimalLimit]="pairData.secondFloatDigit" decimals>
                        </div>
                        <span class="required" *ngIf="serverErrors?.firstbuyAmount">{{
                          serverErrors.firstbuyAmount
                          }}</span>
                      </div>
                      <div class="botOrderRadiGroup">
                        <div class="buttonStyleRadio">
                          <input type="radio" id="limitRadio" name="botOrderType" />
                          <label class="btn btn-default" for="limitRadio">Limit</label>
                        </div>
                        <!-- <div class="buttonStyleRadio">
                          <input type="radio" id="marketRadio" name="botOrderType" />
                          <label class="btn btn-default" for="marketRadio">Market</label>
                        </div> -->
                      </div>
                    </div>
                    <div class="createbot_box">
                      <p _ngcontent-ng-c446327758="" class="bot_preview_title"><i _ngcontent-ng-c446327758=""
                          class="bi bi-info-circle-fill ms-2"></i> Averaging orders</p>

                      <div class="inputgrp_box">
                        <div class="w-100 input-group">
                          <label class="grey">Averaging orders amount, {{pairData.secondCurrencySymbol}}</label>
                          <input aria-label="Username" aria-describedby="basic-addon1" name="avgOrdAmt"
                            (input)="handleTradeChange($event)" formControlName="avgOrdAmt"
                            value="{{formValue.avgOrdAmt}}" class="form-control"
                            [appDecimalLimit]="pairData.secondFloatDigit" decimals>
                        </div>
                        <span class="required" *ngIf="serverErrors?.AveragingorderAmount">{{
                          serverErrors.AveragingorderAmount
                          }}</span>
                      </div>
                      <div class="inputgrp_box center">
                        <div class="w-100 input-group">
                          <label class="grey">Averaging orders quantity</label>
                          <div class="flexNumberInput">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-dash cursor" (click)="handleIncDec('margincallLimit', '-', 1)"></i>
                            </span>
                            <input aria-label="Username" aria-describedby="basic-addon1" name="margincallLimit"
                              (input)="handleTradeChange($event)" formControlName="margincallLimit"
                              value="{{formValue.margincallLimit}}" class="form-control"
                              [appDecimalLimit]="pairData.secondFloatDigit" decimals>
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-plus cursor" (click)="handleIncDec('margincallLimit', '+', 1)"></i>
                            </span>
                          </div>
                        </div>
                        <span class="required" *ngIf="serverErrors?.margincallLimit">{{
                          serverErrors.margincallLimit
                          }}</span>
                        <span class="required" *ngIf="serverErrors?.Buyamount">{{
                          serverErrors.Buyamount
                          }}</span>
                      </div>
                      <div class="inputgrp_box center">
                        <div class="w-100 input-group">
                          <label class="grey">Averaging orders step, %</label>
                          <div class="flexNumberInput">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-dash cursor" (click)="handleIncDec('avgOrdStep', '-', 1)"></i>
                            </span>
                            <input aria-label="Username" aria-describedby="basic-addon1" name="avgOrdStep"
                              (input)="handleTradeChange($event)" formControlName="avgOrdStep"
                              value="{{formValue.avgOrdStep}}" class="form-control"
                              [appDecimalLimit]="pairData.secondFloatDigit" decimals>
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-plus cursor" (click)="handleIncDec('avgOrdStep', '+', 1)"></i>
                            </span>
                          </div>
                          <span class="required" *ngIf="serverErrors?.AvgorderStep">{{
                            serverErrors.AvgorderStep
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="createbot_box">
                      <p _ngcontent-ng-c446327758="" class="bot_preview_title"><i _ngcontent-ng-c446327758=""
                          class="bi bi-info-circle-fill ms-2"></i> Amount multiplier</p>
                      <ngx-slider [(value)]="amtMultiplier" (valueChange)="handleTradeChange($event)"
                        [options]="amtMultiplierOptions" min="0.99" max="2" step="0.01"></ngx-slider>
                    </div>
                    <div class="createbot_box">
                      <p _ngcontent-ng-c446327758="" class="bot_preview_title"><i _ngcontent-ng-c446327758=""
                          class="bi bi-info-circle-fill ms-2"></i> Step multiplier</p>
                      <ngx-slider [(value)]="stepMultiplier" (valueChange)="handleTradeChange($event)"
                        [options]="stepMultiplierOptions" min="0.99" max="2" step="0.01"></ngx-slider>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Position TP & SL
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body px-0">
                    <div class="createbot_box">
                      <p _ngcontent-ng-c446327758="" class="bot_preview_title"><i _ngcontent-ng-c446327758=""
                          class="bi bi-info-circle-fill ms-2"></i> Take Profit</p>
                      <div class="inputgrp_box center">
                        <div class="w-100 input-group">
                          <label class="grey"> Price change, %</label>
                          <div class="flexNumberInput">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-dash cursor" (click)="handleIncDec('takeprofitRatio', '-', 1)"></i>
                            </span>
                            <input aria-label="Username" (input)="handleTradeChange($event)" name="takeprofitRatio"
                              value="{{formValue.takeprofitRatio}}" aria-describedby="basic-addon1" class="form-control"
                              value="6">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-plus cursor" (click)="handleIncDec('takeprofitRatio', '+', 1)"></i>
                            </span>
                          </div>
                        </div>
                        <span class="required" *ngIf="serverErrors?.takeprofitRatio">{{
                          serverErrors.takeprofitRatio
                          }}</span>
                      </div>
                      <div class="inputgrp_box">
                        <div class="w-100 input-group">
                          <label class="grey">Percentage of</label>
                          <input aria-label="Username" aria-describedby="basic-addon1" name="investmentAmount"
                            class="form-control" value="Average Price" disabled="true">
                        </div>
                      </div>
                      <!-- <div class="inputgrp_box">
                        <div class="w-100 input-group">
                          <label class="grey">Averaging orders amount, {{pairData.secondCurrencySymbol}}</label>
                          <input aria-label="Username" aria-describedby="basic-addon1" value="{{formValue.avgOrdStep}}"
                            name="avgOrdStep" (input)="handleTradeChange($event)" formControlName="avgOrdStep"
                            class="form-control" [appDecimalLimit]="pairData.secondFloatDigit" decimals>
                        </div>
                      </div> -->
                      <div class="botOrderRadiGroup">
                        <div class="buttonStyleRadio">
                          <input type="radio" id="limitRadio" name="botOrderType" />
                          <label class="btn btn-default" for="limitRadio">Limit</label>
                        </div>
                      </div>
                      <div class="boxFlexText textColorWhite">
                        <p class="m-0">PNL</p>
                        <p class="m-0">≈ {{formValue.tppnl}} USDT</p>
                      </div>
                    </div>
                    <div class="createbot_box">
                      <div class="boxFlexText mb-3">
                        <p _ngcontent-ng-c446327758="" class="bot_preview_title mb-0"><i _ngcontent-ng-c446327758=""
                            class="bi bi-info-circle-fill ms-2"></i> Stop Loss</p>
                        <div class="form-check form-switch">
                          <input class="form-check-input" [checked]="formValue?.isstoploss"
                            (change)="handlestopLoss($event)" type="checkbox" id="flexSwitchCheckDefault">
                        </div>
                      </div>
                      <div class="inputgrp_box center" *ngIf="formValue?.isstoploss">
                        <div class="w-100 input-group">
                          <label class="grey">Price change, %</label>
                          <div class="flexNumberInput">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-dash cursor" (click)="handleIncDec('stopLoss', '-', 1)"></i>
                            </span>
                            <input aria-label="Username" name="stopLoss" value="{{formValue.stopLoss}}"
                              aria-describedby="basic-addon1" class="form-control" value="6">
                            <span class="input-group-text" id="basic-addon1">
                              <i class="bi bi-plus cursor" (click)="handleIncDec('stopLoss', '+', 1)"></i>
                            </span>
                          </div>
                          <span class="required" *ngIf="serverErrors?.stopLoss">{{
                            serverErrors.stopLoss
                            }}</span>
                        </div>
                      </div>
                      <div class="botOrderRadiGroup" *ngIf="formValue?.isstoploss">
                        <div class="buttonStyleRadio">
                          <input type="radio" id="limitRadio" name="botOrderType" />
                          <label class="btn btn-default" for="limitRadio">Limit</label>
                        </div>
                      </div>
                      <div class="boxFlexText textColorWhite" *ngIf="formValue?.isstoploss">
                        <p class="m-0">PNL</p>
                        <p class="m-0">≈ <span class="redText">-99.85</span> USDT</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="boxFlexText">
    <p class="bot_preview_title mb-0"><i class="bi bi-info-circle-fill me-2"
        data-tooltip-id="my-tooltip-6"></i>Available for bot use</p>
    <p class="pricFont">{{toFixed(wallet?.spotBal,pairData.secondFloatDigit)}} <span class="grey">
        {{pairData.secondCurrencySymbol}}</span></p>
  </div>
  <div class="bgcolor">
    <button type='button' class="primary_btn w-100" *ngIf="!auth.signedIn; else submitbutton"
      (click)="navigateToLogin()">
      LOGIN
    </button>
    <ng-template #submitbutton>
      <button class="continue" (click)="submitForm()">Continue</button>
    </ng-template>
  </div>
</div>

<app-dcaorderplace *ngIf="showNext" orderStep="two" [botDetails]="createDetails"
  [orderPlaceDet]="orderPlaceDet"></app-dcaorderplace>