<app-header></app-header>
<section class="page_header">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500">
        <div class="banner_content">
          <span [innerHtml]="homeimageBanner.content"></span>
          <button class="get_start_btn">
            <a routerLink="/signup">Get Started</a>
          </button>
        </div>
      </div>
      <div class="col-md-6 text-center order-md-last order-first" data-aos="fade-up" data-aos-easing="linear"
        data-aos-duration="500">
        <img src="{{cmsurl + homeimageBanner.image }}" alt="Banner" class="img-fluid" />
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-md-12" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
          (init)="slickInit($event)">
          <div ngxSlickItem *ngFor="let slide of slides" class="slide">
            <!-- <img src="{{ slide.img }}" alt="" width="100%" /> -->
            <img src="{{sliderurl + slide }}" alt="Image" />
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<app-home-market-trend></app-home-market-trend>

<section class="features_section section">
  <div class="container">
    <div class="row">
      <div class="col-md-10 col-lg-8 col-xl-6 m-auto text-center" data-aos="fade-up" data-aos-easing="linear"
        data-aos-duration="1500">
        <h2>Investing in Crypto <span>will be Easier</span> than Ever</h2>
        <h5>
          Sign Up with <span>CRED BULL!</span> enjoy Superfast Trading
          Experience
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="features_panel">
          <div class="features_div" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="200">
            <img src="assets/images/feature_img_01.png" alt="Features" class="img-fluid" />
            <h3>Register</h3>
            <p>Sign Up with your mobile number</p>
            <a routerLink="/signup">Register Now</a>
          </div>
          <div class="features_div" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="400">
            <img src="assets/images/feature_img_02.png" alt="Features" class="img-fluid" />
            <h3>Funds</h3>
            <p>Funds from your bank account</p>
            <a routerLink="/wallet">Wallet</a>
          </div>
          <div class="features_div" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="600">
            <img src="assets/images/feature_img_03.png" alt="Features" class="img-fluid" />
            <h3>Trade</h3>
            <p>Buy, sell and transfer Bitcoin, Ether and 100+ Crypto assets.</p>
            <a routerLink="/spot">Trading</a>
          </div>
        </div>
        <div class="text-center mt-5" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
          <button class="get_start_btn">
            <a routerLink="/signup">Get Started</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section ctaone_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="ctaone_box">
          <div>
            <span [innerHTML]="homeCustomerServed?.content"></span>
            <h5>{{homeCustomerServed?.title}}</h5>
          </div>
          <div>
            <span [innerHTML]="homeServingBc?.content"></span>
            <h5>{{homeServingBc?.title}}</h5>
          </div>
          <div>
            <span [innerHTML]="homeOrderProcessed?.content"></span>
            <h5>{{homeOrderProcessed?.title}}</h5>
          </div>
          <div>
            <span [innerHTML]="homeCoutries?.content"></span>
            <h5>{{homeCoutries?.title}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section why_choose_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mobile_none" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="why_choose_section_img" [ngStyle]="{background: 'url(' + whydarkimage + ') no-repeat center'}">
        </div>
        <!-- <img
          src="assets/images/why_choose_img.png"
          alt="Why Choose"
          class="img-fluid"
        />
        <img
          src="assets/images/why_choose_img_light.png"
          alt="Why Choose"
          class="img-fluid"
        /> -->
      </div>
      <div class="desktop_none">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-center">Why Choose <span>CREDBULL</span> Exchange?</h2>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="200">
            <div class="why_choose_div">
              <img src="assets/images/why_choose_icon_01.png" alt="Why Choose" class="img-fluid" />
              <h3>24/7 Customer Support</h3>
              <p>
                Have questions? Our dedicated customer support team is available around the clock to assist you through Chats and Mail. Your satisfaction is our success.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="400">
            <div class="why_choose_div">
              <img src="assets/images/why_choose_icon_02.png" alt="Why Choose" class="img-fluid" />
              <h3>Robust Security Measures</h3>
              <p>
                Your safety is our priority. With multi-layer encryption and cold storage options, your assets are kept secure from any potential threats.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="600">
            <div class="why_choose_div">
              <img src="assets/images/why_choose_icon_03.png" alt="Why Choose" class="img-fluid" />
              <h3>User-Friendly Interface</h3>
              <p>
                No more confusing interfaces! Our intuitive platform ensures seamless navigation, making it a breeze for both beginners and seasoned traders.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="800">
            <div class="why_choose_div">
              <img src="assets/images/why_choose_icon_04.png" alt="Why Choose" class="img-fluid" />
              <h3>Utility</h3>
              <p>
                Listed tokens In Our CREDBULL Exchange will be automatically Eligible for our Utility Needs Globally and Nationally Such as Flight Tickets booking, Hotel Booking, sightseeing Booking, Mobile recharge, Electricity Bill Pay, DTH Recharge and Fastag.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"
            data-aos-delay="1000">
            <div class="why_choose_div">
              <img src="assets/images/why_choose_icon_05.png" alt="Why Choose" class="img-fluid" />
              <h3>Ultra-Fast Trading</h3>
              <p>
                Say goodbye to frustrating delays! Experience lightning-fast trading executions and stay ahead of market trends with our high-speed order matching system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section ctatwo_section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="ctatwo_box">
          <div>
            <h2><span>Start Your</span> Crypto Journey Now!</h2>
            <p>
              With the <span>CRED BULL</span> App and website, trading has
              been easier.
            </p>
            <button class="primary_btn">
              <a routerLink="/signup">Sign Up</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section mobile_app_section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <span [innerHtml]="homeimageMobileApp.content"></span>
        <ul>
          <li>
            <a href="/" target="_blank">
              <img src="assets/images/app_store.png" alt="Why Choose" class="img-fluid" />
              <div>
                <h5>Download from</h5>
                <h2>Appstore</h2>
              </div>
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.credbullexchange&hl=en_IN" target="_blank">
              <img src="assets/images/google_play_store.png" alt="Why Choose" class="img-fluid" />
              <div>
                <h5>Download from</h5>
                <h2>Google Play</h2>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 col-md-6" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="text-center">
          <img src="{{ cmsurl + homeimageMobileApp.image}}" alt="Why Choose" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>