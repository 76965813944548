import { Component } from '@angular/core';

@Component({
  selector: 'app-gridbot',
  templateUrl: './gridbot.component.html',
  styleUrls: ['./gridbot.component.css'],
})

export class GridbotComponent {
  isChecked: boolean = false; 
  isChecked2: boolean = false;
  selectedDate: Date | null = null;

  constructor() {}
}
