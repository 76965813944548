import { Component } from '@angular/core';

@Component({
  selector: 'app-dcabotcreate',
  templateUrl: './dcabotcreate.component.html',
  styleUrls: ['./dcabotcreate.component.css']
})
export class DcabotcreateComponent {

}
