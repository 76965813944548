import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(private http: HttpClient) { }

    getSubsPlans = () => {
        return this.http.get(environment.botApiUrl + 'api/subscription/plan')
    }
    getSubscription = (params: any) => {
        return this.http.get(environment.botApiUrl + 'api/subscription/list', { params })
    }
    purchasePlan = (data: any) => {
        return this.http.post(environment.botApiUrl + 'api/subscription/purchase', data)
    }
    upgradePlan = (data: any) => {
        return this.http.post(environment.botApiUrl + 'api/subscription/upgradePlan', data)
    }
    getUserPlan = () => {
        return this.http.get(environment.botApiUrl + 'api/subscription/userplan')
    }

}
