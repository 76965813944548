<div class="bot-order">
  <div
    class="back-drop"
    [ngStyle]="{
      opacity: isCollapsed ? 1 : 0.3
    }"
  >
    <div class="bot-order-head">
      <h4>DCA Bot Details</h4>
      <button class="bot-clear">
        <img src="assets/images/botclear.svg" alt="close" title="close" />
      </button>
    </div>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="strategies-tab-pane"
        role="tabpanel"
        aria-labelledby="strategies-tab"
        tabindex="0"
      >
        <ul
          class="nav nav-tabs bottab mt-2 dcabot-details-tab-head"
          id="myTab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="coin_one-tab"
              type="button"
              data-bs-toggle="tab"
              data-bs-target="#coin_one"
              role="tab"
              aria-controls="coin_one"
              aria-selected="true"
            >
              Performance
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="coin_two-tab"
              type="button"
              data-bs-toggle="tab"
              data-bs-target="#coin_two"
              role="tab"
              aria-controls="coin_two"
              aria-selected="false"
            >
              Settings
            </button>
          </li>
        </ul>
        <div class="tab-content dcabot-details-tab" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="coin_one"
            role="tabpanel"
            aria-labelledby="coin_one-tab"
          >
            <h5 class="greenTxt">
              <img
                src="../../../../assets/images/checked.png"
                alt="Icon"
                class="img-fluid me-2"
                width="14px"
              />Long
            </h5>
            <h5>
              <img
                src="../../../../assets/images/binance.svg"
                alt="Icon"
                class="img-fluid me-2"
                width="20px"
              />ETH/USDT <span class="botType">DCA</span>
            </h5>
            <p class="greenTxt">Long</p>
            <div class="d-flex gap-3 detail-box-head">
              <div class="detail-box">
                <div class="d-flex gap-1 align-items-center">
                  <p>Bot profit (PNL with Fee)</p>
                  <img
                    src="../../../../assets/images/alert_img.png"
                    alt="Icon"
                    class="img-fluid"
                  />
                </div>
                <h5 class="redText">-3.91%</h5>
                <h6>0.38</h6>
                <p class="mb-0">USDT</p>
              </div>

              <div class="detail-box">
                <div class="d-flex gap-1 align-items-center">
                  <p>Bot profit (PNL with Fee)</p>
                  <img
                    src="../../../../assets/images/alert_img.png"
                    alt="Icon"
                    class="img-fluid"
                  />
                </div>
                <h5 class="redText">-3.91%</h5>
                <h6>0.38</h6>
                <p class="mb-0">USDT</p>
              </div>
            </div>

            <div class="bot_preview_box_wrapper">
              <div>
                <div class="bot_preview_box">
                  <div>
                    <span>Unrealized</span><span class="border_line"></span
                    ><span>USDT/-3.91</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="bot_preview_title mt-2">In Orders</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>Base currency</span><span class="border_line"></span
                    ><span>O ETH</span>
                  </div>
                  <div>
                    <span>Value</span><span class="border_line"></span
                    ><span>9.9122 USDT</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="bot_preview_title mt-2">Invested funds</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>Base currency</span><span class="border_line"></span
                    ><span>O ETH</span>
                  </div>
                  <div>
                    <span>Quote currency</span><span class="border_line"></span
                    ><span>25 USDT</span>
                  </div>
                  <div>
                    <span>Value</span><span class="border_line"></span
                    ><span>25 USDT</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="bot_preview_title mt-2">General info</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>Transactions</span><span class="border_line"></span
                    ><span>1</span>
                  </div>
                  <div>
                    <span>Trading time</span><span class="border_line"></span
                    ><span>Omin</span>
                  </div>
                  <div>
                    <span>Bot ID</span><span class="border_line"></span
                    ><span>664f100c1148e524750a9</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="primary_btn w-100 mt-3"
              data-bs-toggle="modal"
              data-bs-target="#table_bot_modal"
            >
              <span>Bot orders</span>
            </button>
          </div>

          <div
            class="tab-pane fade"
            id="coin_two"
            role="tabpanel"
            aria-labelledby="coin_two-tab"
          >
            <div class="bot_preview_box_wrapper">
              <div>
                <h3 class="bot_preview_title mt-2">General settings</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>Exchange</span><span class="border_line"></span
                    ><span>Binance</span>
                  </div>
                  <div>
                    <span>Pair</span><span class="border_line"></span
                    ><span>ETH-USDT</span>
                  </div>
                  <div>
                    <span>Strategy</span><span class="border_line"></span
                    ><span>Long</span>
                  </div>
                  <div>
                    <span>Investment</span><span class="border_line"></span
                    ><span>25 USDT</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="bot_preview_title mt-2">Bot settings</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>Base order</span><span class="border_line"></span
                    ><span>10 USDT</span>
                  </div>
                  <div>
                    <span>Base order type</span><span class="border_line"></span
                    ><span>Market</span>
                  </div>
                  <div>
                    <span>Place base order</span
                    ><span class="border_line"></span><span>Immediately</span>
                  </div>
                  <div>
                    <span>Avg. orders</span><span class="border_line"></span
                    ><span>15 USDT</span>
                  </div>
                  <div>
                    <span>Avg. orders qty</span><span class="border_line"></span
                    ><span>2</span>
                  </div>
                  <div>
                    <span>Avg. orders step</span
                    ><span class="border_line"></span><span>0.5%</span>
                  </div>
                  <div>
                    <span>Amount multiplier</span
                    ><span class="border_line"></span><span>x1.3</span>
                  </div>
                  <div>
                    <span>Step multiplier</span><span class="border_line"></span
                    ><span>x1.3</span>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="bot_preview_title mt-2">Position TP & SL</h3>
                <div class="bot_preview_box">
                  <div>
                    <span>TP price change</span><span class="border_line"></span
                    ><span>2%</span>
                  </div>
                  <div>
                    <span>TP percentage of</span
                    ><span class="border_line"></span><span>Average price</span>
                  </div>
                  <div>
                    <span>TP order type</span><span class="border_line"></span
                    ><span>Market</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bgcolor d-flex align-items-center gap-2 collapse_btn">
    <!-- Collapsible Content -->
    <div
      class="collapsible-content"
      [@contentAnimation]="isCollapsed ? 'hidden' : 'visible'"
      *ngIf="!isCollapsed"
    >
      <div class="bot-order-head">
        <h4>Bot actions</h4>
        <button class="bot-clear" (click)="toggleCollapse()">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Manual position averaging</h5>
              <p>
                Average the price of a position by manually adding funds
                according to the current market price.
              </p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Modify Bot</h5>
              <p>Modify the current bot settings.</p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Stop Bot</h5>
              <p>Stop the bot's operations completely.</p>
            </div>
          </div>
        </a>
        <a href="#">
          <div class="bot_preview_box">
            <div>
              <h5>Deactivate Bot</h5>
              <p>Deactivate the bot's operations completely.</p>
            </div>
          </div>
        </a>
      </div>
      <!-- "Hide Content" Button -->
      <button class="continue bottom-btn" (click)="toggleCollapse()">
        Cancel
      </button>
    </div>

    <!-- "Show Content" Button -->
    <button
      class="toggle-btn continue"
      *ngIf="isCollapsed"
      (click)="toggleCollapse()"
    >
      Start bot
    </button>
  </div>
</div>
