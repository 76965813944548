import { Injectable, NgModule, enableProdMode, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { ScrollSpyModule } from 'ng-scroll-spy';
import { StoreModule, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { QRCodeModule } from 'angularx-qrcode';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CountUpModule } from 'ngx-countup';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
const customParser = require('socket.io-msgpack-parser');

// Import component
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { SidebarComponent } from './views/sidebar/sidebar.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SigninComponent } from './views/signin/signin.component';
import { HeaderDashboardComponent } from './views/header-dashboard/header-dashboard.component';
import { SignupComponent } from './views/signup/signup.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SecurityComponent } from './views/security/security.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { IdentificationComponent } from './views/identification/identification.component';
import { BankInfoComponent } from './views/bank-info/bank-info.component';
import { AddBankComponent } from './views/add-bank/add-bank.component';
import { HistoryComponent } from './views/history/history.component';
import { SupportTicketComponent } from './views/support-ticket/support-ticket.component';
import { EditProfileComponent } from './views/edit-profile/edit-profile.component';
import { MarketComponent } from './views/market/market.component';
import { MarketHomeComponent } from './views/market-home/market-home.component';
import { MarketInnerComponent } from './views/market-inner/market-inner.component';
import { NotificationComponent } from './views/notification/notification.component';
import { WalletComponent } from './views/wallet/wallet.component';
import { DepositCryptoComponent } from './views/deposit-crypto/deposit-crypto.component';
import { WithdrawCryptoComponent } from './views/withdraw-crypto/withdraw-crypto.component';
import { FeeComponent } from './views/fee/fee.component';
import { FeeHomeComponent } from './views/fee-home/fee-home.component';
import { FeeInnerComponent } from './views/fee-inner/fee-inner.component';
import { SpotComponent } from './views/spot/spot.component';
import { HeaderTradeComponent } from './views/header-trade/header-trade.component';
import { WithdrawFiatComponent } from './views/withdraw-fiat/withdraw-fiat.component';
import { DepositFiatComponent } from './views/deposit-fiat/deposit-fiat.component';
import { LaunchpadComponent } from './views/launchpad/launchpad.component';
import { LaunchpadDetailsComponent } from './views/launchpad-details/launchpad-details.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { ContactComponent } from './views/contact/contact.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { VerifyComponent } from './views/verify/verify.component';
import { OrderplaceComponent } from './components/spot/orderplace/orderplace.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
// Import store
import { authReducers } from './store/auth';
import { userReducers } from './store/user';
import { userSettingReducers } from './store/usersetting';
import { CustomHttpInterceptor } from './core/interceptors/http.interceptor';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'src/environments/environment';
import { walletReducers } from './store/wallet';
import { currencyReducers } from './store/currency';
import { priceCnvReducers } from './store/pricecnv';
import { pairReducers } from './store/pair';
import { MarketpairComponent } from './components/spot/marketpair/marketpair.component';
import { TickerpriceComponent } from './components/spot/tickerprice/tickerprice.component';
import { TradechartComponent } from './components/spot/tradechart/tradechart.component';
import { EditBankComponent } from './views/edit-bank/edit-bank.component';
import { OrderbookComponent } from './components/spot/orderbook/orderbook.component';
import { TradeorderhistoryComponent } from './components/spot/tradeorderhistory/tradeorderhistory.component';
import { tradePairReducers } from './store/tradepair';
import { LimitorderComponent } from './components/spot/limitorder/limitorder.component';
import { MarketorderComponent } from './components/spot/marketorder/marketorder.component';
import { tradeAssetReducers } from './store/trade-asset';
import { marketPriceReducers } from './store/marketprice';
import { ViewBalanceComponent } from './components/spot/view-balance/view-balance.component';
import { LimitorderFormComponent } from './components/spot/limitorder-form/limitorder-form.component';
import { OpenordersComponent } from './components/spot/openorders/openorders.component';
import { RecentTradesComponent } from './components/spot/recent-trades/recent-trades.component';
import { MarketorderFormComponent } from './components/spot/marketorder-form/marketorder-form.component';
import { UserorderlistComponent } from './components/spot/userorderlist/userorderlist.component';
import { dashboardReducers } from './store/dashboard';

// directives
import { DecimalLimitDirective } from 'src/app/core/directives/decimalLimit.directive';
import { CancelorderComponent } from './components/spot/cancelorder/cancelorder.component';
import { OrderhistoryComponent } from './components/spot/orderhistory/orderhistory.component';
import { TradehistoryComponent } from './components/spot/tradehistory/tradehistory.component';
import { TextFilterPipe } from './core/pipes/testFilterPipe';
import { LastTransactionComponent } from './components/dashboard/last-transaction/last-transaction.component';
import { LoginHistoryComponent } from './components/dashboard/login-history/login-history.component';
import { TwoFAComponent } from './components/dashboard/two-fa/two-fa.component';
import { KycVerificationComponent } from './components/dashboard/kyc-verification/kyc-verification.component';
import { BankVerificationComponent } from './components/dashboard/bank-verification/bank-verification.component';
import { BankDetailsComponent } from './components/dashboard/bank-details/bank-details.component';
import { SupportticketFormComponent } from './components/supportticket/supportticket-form/supportticket-form.component';
import { SupporttiketDetailsComponent } from './components/supportticket/supporttiket-details/supporttiket-details.component';
import { TransactionhistoryComponent } from './components/history/transactionhistory/transactionhistory.component';
import { OrderHistoryComponent } from './components/history/order-history/order-history.component';
import { TradeHistoryComponent } from './components/history/trade-history/trade-history.component';
import { HomeMarketTrendComponent } from './components/home-market-trend/home-market-trend.component';
import { ThemeComponent } from './components/theme/theme.component';
import { NumberDirective } from './core/directives/numbersOnly.directive';
import { DecimalsDirective } from './core/directives/decimals.directive';
import { LaunchpadListComponent } from './views/launchpad-list/launchpad-list.component';
import { AlphaNumericDirective } from './core/directives/alphaNumeric.directives';
import { AlphaDirective } from './core/directives/alpha.directivies';
import { ShareService } from './services/share.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { CompleteProfileComponent } from './views/complete-profile/complete-profile.component';
import { ChangePhoneNumberComponent } from './views/change-phone-number/change-phone-number.component';
import { BuyCryptoComponent } from './views/buy-crypto/buy-crypto.component';
import { OnramperHomeComponent } from './components/onramper/onramper-home/onramper-home.component';
import { OnramperWidgetComponent } from './components/onramper/onramper-widget/onramper-widget.component';
import { LoaderComponent } from './components/loader/loader.component';
import { extModules } from 'src/build-specifics';
import { HighchartsChartModule } from 'highcharts-angular';
import { UppercaseDirective } from './core/directives/uppercase.directive';
import { AppTradeChartComponent } from './components/spot/app-trade-chart/app-trade-chart.component';
// import { OnrampMoneyComponent } from './components/onramp-money/onramp-money.component';
import { OnrampMoneyHomeComponent } from './components/onramp-money/onramp-money-home/onramp-money-home.component';
import { OnrampHostedModeComponent } from './components/onramp-money/onramp-hosted-mode/onramp-hosted-mode.component';
import { OnrampOverlayModeComponent } from './components/onramp-money/onramp-overlay-mode/onramp-overlay-mode.component';
import { KycComponent } from './components/kyc/kyc.component';
import { DeactivationComponent } from './views/deactivation/deactivation.component';
import { AmlPageComponent } from './views/aml-page/aml-page.component';
import { WebcamModule } from 'ngx-webcam';
import { RecaptchaModule } from "ng-recaptcha";
import { DCABOTComponent } from './views/dcabot/dcabot.component';
import { HeaderBotComponent } from './views/header-bot/header-bot.component';
import { SubscriptionPageComponent } from './views/subscription-page/subscription-page.component';
import { DcabotpairComponent } from './components/dcabot/dcabotpair/dcabotpair.component';
import { DcabotorderComponent } from './components/dcabot/dcabotorder/dcabotorder.component';
import { MyexchangeComponent } from './views/myexchange/myexchange.component';
import { DcabotpreviewComponent } from './components/dcabot/dcabotpreview/dcabotpreview.component';
import { DcabotdetailsComponent } from './components/dcabot/dcabotdetails/dcabotdetails.component';
import { DcabotactionComponent } from './components/dcabot/dcabotaction/dcabotaction.component';
import { DcabotstopComponent } from './components/dcabot/dcabotstop/dcabotstop.component';
import { DcabotcreateComponent } from './components/dcabot/dcabotcreate/dcabotcreate.component';
import { GridbotComponent } from './views/gridbot/gridbot.component';
import { GridbotpreviewComponent } from './components/gridbot/gridbotpreview/gridbotpreview.component';
import { GridbotpairComponent } from './components/gridbot/gridbotpair/gridbotpair.component';
import { GridbotcreateComponent } from './components/gridbot/gridbotcreate/gridbotcreate.component';
import { GridbotdetailsComponent } from './components/gridbot/gridbotdetails/gridbotdetails.component';
import { GridbotstopComponent } from './components/gridbot/gridbotstop/gridbotstop.component';
import { ChartComponent } from './components/dcabot/chart/chart.component';
import { DcaorderplaceComponent } from './components/dcabot/dcaorderplace/dcaorderplace.component';
import { DcaorderhistoryComponent } from './components/dcabot/dcaorderhistory/dcaorderhistory.component';
import { positionDetailsReducers } from './store/positionDetails';
import { SubscriptionComponent } from './components/subscription/subscription.component';
const JWT_Module_Options: JwtModuleOptions = {
  config: {},
};

const connectionOptions = {
  "transports": ["websocket"],
  "parser": customParser,
  // "forceNew": true,
  // "reconnection": true,
  // "reconnectionDelay": 2000,
  // "reconnectionDelayMax": 600,
  // "reconnectionAttempts": 10,
  // "timeout": 10000,
}

@Injectable()
export class userSocket extends Socket {
  constructor() {
    super({
      url: environment.userApiUrl, options: {}
    });
  }
}

@Injectable()
export class spotSocket extends Socket {
  constructor() {
    super({
      url: environment.spotApiUrl, options: {}
    });
  }
}
export class botSocket extends Socket {
  constructor() {
    super({
      url: environment.botApiUrl, options: {}
    });
  }
}

if (environment.production) {
  enableProdMode();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SidebarComponent,
    DashboardComponent,
    SigninComponent,
    HeaderDashboardComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SecurityComponent,
    ChangePasswordComponent,
    IdentificationComponent,
    BankInfoComponent,
    AddBankComponent,
    HistoryComponent,
    SupportTicketComponent,
    EditProfileComponent,
    MarketComponent,
    MarketHomeComponent,
    MarketInnerComponent,
    NotificationComponent,
    WalletComponent,
    DepositCryptoComponent,
    WithdrawCryptoComponent,
    FeeComponent,
    FeeHomeComponent,
    FeeInnerComponent,
    SpotComponent,
    HeaderTradeComponent,
    WithdrawFiatComponent,
    DepositFiatComponent,
    LaunchpadComponent,
    LaunchpadDetailsComponent,
    AboutUsComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    LoginFormComponent,
    RegisterFormComponent,
    VerifyComponent,
    MarketpairComponent,
    TickerpriceComponent,
    TradechartComponent,
    EditBankComponent,
    OrderbookComponent,
    TradeorderhistoryComponent,
    OrderplaceComponent,
    LimitorderComponent,
    MarketorderComponent,
    ViewBalanceComponent,
    LimitorderFormComponent,
    OpenordersComponent,
    RecentTradesComponent,
    MarketorderFormComponent,
    UserorderlistComponent,
    DecimalLimitDirective,
    NumberDirective,
    DecimalsDirective,
    AlphaNumericDirective,
    AlphaDirective,
    UppercaseDirective,
    CancelorderComponent,
    OrderhistoryComponent,
    TradehistoryComponent,
    TextFilterPipe,
    LastTransactionComponent,
    LoginHistoryComponent,
    TwoFAComponent,
    KycVerificationComponent,
    BankVerificationComponent,
    BankDetailsComponent,
    SupportticketFormComponent,
    SupporttiketDetailsComponent,
    TransactionhistoryComponent,
    OrderHistoryComponent,
    TradeHistoryComponent,
    HomeMarketTrendComponent,
    ThemeComponent,
    LaunchpadListComponent,
    ProfileFormComponent,
    CompleteProfileComponent,
    ChangePhoneNumberComponent,
    BuyCryptoComponent,
    OnramperHomeComponent,
    OnramperWidgetComponent,
    LoaderComponent,
    AppTradeChartComponent,
    // OnrampMoneyComponent,
    OnrampMoneyHomeComponent,
    OnrampHostedModeComponent,
    OnrampOverlayModeComponent,
    KycComponent,
    DeactivationComponent,
    AmlPageComponent,
    DCABOTComponent,
    HeaderBotComponent,
    SubscriptionPageComponent,
    DcabotpairComponent,
    DcabotorderComponent,
    MyexchangeComponent,
    DcabotpreviewComponent,
    DcabotdetailsComponent,
    DcabotactionComponent,
    DcabotstopComponent,
    SubscriptionComponent,
    DcabotcreateComponent,
    GridbotComponent,
    GridbotpreviewComponent,
    GridbotpairComponent,
    GridbotcreateComponent,
    GridbotdetailsComponent,
    GridbotstopComponent,
    ChartComponent,
    DcaorderplaceComponent,
    DcaorderhistoryComponent
  ],
  imports: [
    RecaptchaModule,
    WebcamModule,
    BrowserModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaV3Module,
    SocketIoModule,
    JwtModule.forRoot(JWT_Module_Options),
    ToastrModule.forRoot({
      // timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    SlickCarouselModule,
    NgScrollbarModule,
    MatInputModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    ScrollSpyModule,
    NgxMatIntlTelInputComponent,
    StoreModule.forRoot({}, {}),
    // InfiniteScrollModule,
    QRCodeModule,
    CountUpModule,
    RxReactiveFormsModule,
    NgOtpInputModule,
    NgxSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    provideStore({
      auth: authReducers,
      user: userReducers,
      usersetting: userSettingReducers,
      wallet: walletReducers,
      currency: currencyReducers,
      priceconversion: priceCnvReducers,
      pair: pairReducers,
      tradepair: tradePairReducers,
      tradeasset: tradeAssetReducers,
      marketprice: marketPriceReducers,
      dashboard: dashboardReducers,
      positionDetails: positionDetailsReducers,
    }),
    extModules,
    userSocket,
    spotSocket,
    botSocket,
    ShareService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
