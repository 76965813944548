<header class="header header--fixed">
  <div class="container-fluid">
    <div class="header__content">
      <nav class="navbar bg-body-dark navbar-expand-xl">
        <a href="/" class="header__logo">
          <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="" />
          <!-- <img [src]="theme == 'dark' ? 'assets/images/logo-light.svg': 'assets/images/logo.svg'" alt="Logo"
            class="img-fluid" /> -->
        </a>
        <div class="dash_top_header">
          <button class="header__btn" type="button">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <app-theme class="dashboard_theme"></app-theme>
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item" >
              <span class="withdraw_btn cursor-pointer" (click)="switchNearMalls()">Utility Services</span>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/launchpad" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Launchpad</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/dcabot" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">DCA</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/subscriptionpage" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Subscription</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/market-inner" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Market</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/fee-inner" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Fees</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/spot" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Trade</a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex" routerLink="/notification" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                Notification 
                <span class="notify_count mx-1" *ngIf="unread_count > 0">{{unread_count}}</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/buy-crypto" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">Buy Crypto</a>
            </li>
            <li>
              <app-theme></app-theme>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>