<form [formGroup]="form">
    <div class="sign_group sign_group_input">
        <label>Price</label>
        <div class="primary_inp_grp">
            <input type="text" class="sign_input" placeholder="{{toFixedDown(0, tradePair.secondFloatDigit)}}"
                [appDecimalLimit]="tradePair.secondFloatDigit"
                [value]="truncateDecimals(marketPrice.markPrice, tradePair.secondFloatDigit)" readOnly="true"
                decimals />
            <div class="trade_buysell_control">
                <span>{{tradePair.secondCurrencySymbol}}</span>
            </div>
        </div>
    </div>
    <div class="sign_group sign_group_input">
        <label>{{ buyorsell === 'buy' ? 'Order Value': 'Amount'}}</label>
        <div class="primary_inp_grp">
            <!-- <input type="text" class="sign_input" [formControlName]="buyorsell == 'buy' ? 'orderValue' : 'amount'" -->
            <input (click)="checktds(tradePair.firstCurrencySymbol, tradePair.secondCurrencySymbol)" type="text" class="sign_input" [formControlName]="buyorsell == 'buy' ? 'orderValue' : 'amount'"
                placeholder="{{truncateDecimals(0, buyorsell === 'buy' ? tradePair.secondFloatDigit: tradePair.firstFloatDigit)}}"
                [appDecimalLimit]="buyorsell === 'buy' ? tradePair.secondFloatDigit: tradePair.firstFloatDigit"
                decimals />
            <div class="trade_buysell_control">
                <span>{{ buyorsell === 'buy' ? tradePair.secondCurrencySymbol: tradePair.firstCurrencySymbol}}</span>
                <div class="increament_btn_grp">
                    <button type="button"
                        (click)="IncDec(buyorsell === 'buy' ? 'orderValue': 'amount', 'dec')" [disabled]="!auth.signedIn">-</button>
                    <button type="button"
                        (click)="IncDec(buyorsell === 'buy' ? 'orderValue': 'amount','inc')" [disabled]="!auth.signedIn">+</button>
                </div>
            </div>
        </div>

        <div *ngIf="buyorsell === 'buy' && orderValue?.invalid && (orderValue?.touched || submitted)">
            <span class="required" *ngIf="orderValue?.errors?.['required']">Order Value field is required</span>
        </div>
        <span class="required" *ngIf="buyorsell === 'buy' && serverErrors?.orderValue">
            {{ serverErrors.orderValue }}
        </span>

        <div *ngIf="buyorsell === 'sell' && amount?.invalid && (amount?.touched || submitted)">
            <span class="required" *ngIf="amount?.errors?.['required']">Amount field is required</span>
        </div>
        <span class="required" *ngIf="buyorsell === 'sell' && serverErrors?.amount">
            {{ serverErrors.amount }}
        </span>

    </div>
    <!-- <ul class="buypercent"> -->
    <ul class="buypercent" (click)="checktds(tradePair.firstCurrencySymbol, tradePair.secondCurrencySymbol)" >
        <li (click)="handleSetPercentage(0)">
            <button type="button" [ngClass]="{'active': buyPercentage === 0}" [disabled]="!auth.signedIn">0%</button>
        </li>
        <li (click)="handleSetPercentage(25)">
            <button type="button" [ngClass]="{'active': buyPercentage === 25}" [disabled]="!auth.signedIn">25%</button>
        </li>
        <li (click)="handleSetPercentage(50)">
            <button type="button" [ngClass]="{'active': buyPercentage === 50}" [disabled]="!auth.signedIn">50%</button>
        </li>
        <li (click)="handleSetPercentage(75)">
            <button type="button" [ngClass]="{'active': buyPercentage === 75}" [disabled]="!auth.signedIn">75%</button>
        </li>
        <li (click)="handleSetPercentage(100)">
            <button type="button" [ngClass]="{'active': buyPercentage === 100}"
                [disabled]="!auth.signedIn">100%</button>
        </li>
    </ul>
    <button type='button' class="primary_btn w-100" *ngIf="!auth.signedIn; else submitbutton"
        (click)="navigateToLogin()">
        LOGIN
    </button>
    <ng-template #submitbutton>
        <button type="button" [ngClass]="{'trade_buy_btn': buyorsell === 'buy', 'trade_sell_btn': buyorsell === 'sell'}"
            (click)="submitForm()">
            {{buyorsell | titlecase}} <span>{{tradePair.firstCurrencySymbol}}</span>
        </button>
    </ng-template>
    <div>
        <span *ngIf="tdsapply">1% TDS charge will be deducted</span>
    </div>
</form>
