<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="strategies-tab-pane" role="tabpanel" aria-labelledby="strategies-tab"
        tabindex="0">
        <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="spot-bot-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#spot-bot" role="tab" aria-controls="spot-bot" aria-selected="true">
                    Spot Bots ({{data.length}})
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="dca-bot-history-tab" type="button" data-bs-toggle="tab"
                    data-bs-target="#dca-bot-history" role="tab" aria-controls="dca-bot-history" aria-selected="false">
                    Bot History
                </button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="spot-bot" role="tabpanel" aria-labelledby="spot-bot-tab">
                <div class="bot-history-filter">
                    <p>Sum, Current value $ {{totCurrent}}</p>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Pair/Bot Type</th>
                                <th>Current Value</th>
                                <th>Total PNL</th>
                                <th>TXNS/Time</th>
                                <th>Investment</th>
                                <th>Take Profit</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of data">
                            <tr>
                                <td>
                                    {{item.pairName}}<span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>{{item.currentvalue}}</td>
                                <td>
                                    {{item.pl}}% <span class="valueInPrice">{{item.pnlval}} USDT</span>
                                </td>
                                <td>{{item.txnDate}}</td>
                                <td>{{item.investment}}</td>
                                <td>{{item.takeProfit}}% USDT</td>
                            </tr>
                            <!-- <tr>
                  <td>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    Binance
                  </td>
                  <td>
                    ETH/USDT <span class="botType">DCA</span>
                    <span class="botAction">Long</span>
                  </td>
                  <td>40.0368</td>
                  <td>
                    80.46% <span class="valueInPrice">17.85 USDT</span>
                  </td>
                  <td>3 / 10m 19h 54mins</td>
                  <td>22.1868</td>
                  <td>2.00% USDT</td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    Binance
                  </td>
                  <td>
                    ETH/USDT <span class="botType">DCA</span>
                    <span class="botAction">Long</span>
                  </td>
                  <td>40.0368</td>
                  <td>
                    80.46% <span class="valueInPrice">17.85 USDT</span>
                  </td>
                  <td>3 / 10m 19h 54mins</td>
                  <td>22.1868</td>
                  <td>2.00% USDT</td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    Binance
                  </td>
                  <td>
                    ETH/USDT <span class="botType">DCA</span>
                    <span class="botAction">Long</span>
                  </td>
                  <td>40.0368</td>
                  <td>
                    80.46% <span class="valueInPrice">17.85 USDT</span>
                  </td>
                  <td>3 / 10m 19h 54mins</td>
                  <td>22.1868</td>
                  <td>2.00% USDT</td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                    Binance
                  </td>
                  <td>
                    ETH/USDT <span class="botType">DCA</span>
                    <span class="botAction">Long</span>
                  </td>
                  <td>40.0368</td>
                  <td>
                    80.46% <span class="valueInPrice">17.85 USDT</span>
                  </td>
                  <td>3 / 10m 19h 54mins</td>
                  <td>22.1868</td>
                  <td>2.00% USDT</td>
                </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" id="dca-bot-history" role="tabpanel" aria-labelledby="dca-bot-history-tab">
                <!-- <div class="noActiveBots">
            <img src="assets/images/noactivebots.svg" class="img-fluid" alt="icon" />
            <p>You don’t have active bots</p>
            <button class="btn startButton">Start NewBot</button>
          </div> -->
                <!-- <div class="bot-history-filter">
            <div class="bh-filter-left">
              <p>Sum, Current value $ 40.04</p>
              <p>
                Sum, bot profit $ -4.34
                <span class="redText">-0.45%</span>
              </p>
              <p>Sum, Investment $ 966.38</p>
            </div>
            <div class="bh-filter-right">
              <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <button class="continue">
                <img src="../../../assets/images/date_picker.png" alt="date" title="date" />
              </button>
              <button class="startButton">Download CSV</button>
            </div>
          </div> -->

                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Exchange</th>
                                <th>Pair/Bot Type</th>
                                <th>Current Value</th>
                                <th>PNL (With Fee)</th>
                                <th>TXNS/Time</th>
                                <th>Inverstment</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td>
                                <td>
                                    ETH/USDT <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>40.0368</td>
                                <td>
                                    80.46% <span class="valueInPrice">17.85 USDT</span>
                                </td>
                                <td>3 / 16mins</td>
                                <td>22.1868</td>
                                <td>
                                    <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td>
                                <td>
                                    ETH/USDT <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>40.0368</td>
                                <td>
                                    80.46% <span class="valueInPrice">17.85 USDT</span>
                                </td>
                                <td>3 / 16mins</td>
                                <td>22.1868</td>
                                <td>
                                    <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td>
                                <td>
                                    ETH/USDT <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>40.0368</td>
                                <td>
                                    80.46% <span class="valueInPrice">17.85 USDT</span>
                                </td>
                                <td>3 / 16mins</td>
                                <td>22.1868</td>
                                <td>
                                    <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td>
                                <td>
                                    ETH/USDT <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>40.0368</td>
                                <td>
                                    80.46% <span class="valueInPrice">17.85 USDT</span>
                                </td>
                                <td>3 / 16mins</td>
                                <td>22.1868</td>
                                <td>
                                    <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="assets/images/binance.svg" alt="Binance" title="Binance" />
                                    Binance
                                </td>
                                <td>
                                    ETH/USDT <span class="botType">DCA</span>
                                    <span class="botAction">Long</span>
                                </td>
                                <td>40.0368</td>
                                <td>
                                    80.46% <span class="valueInPrice">17.85 USDT</span>
                                </td>
                                <td>3 / 16mins</td>
                                <td>22.1868</td>
                                <td>
                                    <span class="greenTxt">Closed</span> Dec 5, 12.24 PM
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>