import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import isEmpty from 'src/app/lib/isEmpty';
import { BotService } from 'src/app/services/bot.service';

// Import facade
import { PairFacade } from '../../store/botpair'
import { AuthFacade } from 'src/app/store/auth';
import { WalletService } from 'src/app/services/wallet.service';
import { TradeAssetFacade } from 'src/app/store/bot-trade-asset';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { Store } from '@ngrx/store';
import { SocketService } from 'src/app/services/socket.service';
// import { TradeAssetFacade } from 'src/app/store/trade-asset';
// import { MarketPriceFacade } from 'src/app/store/marketprice';

@Component({
  selector: 'app-dcabot',
  templateUrl: './dcabot.component.html',
  styleUrls: ['./dcabot.component.css']
})
export class DCABOTComponent {
  isEmpty = isEmpty
  tikerRoot: any
  auth: any = {}
  pairData: any = {}
  posDetails: any = {}
  constructor(
    private route: ActivatedRoute,
    private service: BotService,
    private router: Router,
    private pairFacade: PairFacade,
    private authFacade: AuthFacade,
    private walletService: WalletService,
    private tradeAssetFacade: TradeAssetFacade,
    private socketService: SocketService,
    private store: Store<{ positionDetails: PositionDetailsFacade }>
  ) {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
  }
  ngOnInit() {
    this.getPairs()
    // window.onresize = () => this.isMobileLayout = window.innerWidth <= 1199;
    // window.onload = () => this.isMobileLayout = window.innerWidth <= 1199;
    this.route.paramMap.subscribe(params => {
      this.tikerRoot = params.get('tikerRoot');
      if (!isEmpty(this.tikerRoot)) {
        this.socketService.emit("subscribe", this.tikerRoot.replace("_", ""));
      }
      // this.tradePairFacade.tradePair$.subscribe((pair) => {
      // })
    });
    this.store.select('positionDetails').subscribe((posDet: any) => {
      this.posDetails = posDet.positionDetails;
    });
  }

  ngOnDestroy() {
    if (!isEmpty(this.tikerRoot)) {
      this.socketService.emit("unSubscribe", this.tikerRoot.replace("_", ""));
    }
  }
  getPairs() {
    this.service.getPairList()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (isEmpty(this.tikerRoot)) {
              let pair = `${v.result[0].firstCurrencySymbol}_${v.result[0].secondCurrencySymbol}`
              this.router.navigateByUrl("/dcabot/" + pair)
            } else {
              this.pairFacade.setPair(v.result)
              const selectedPair = v.result.find((el: any) => el.tikerRoot === this.tikerRoot.replace('_', ''))

              this.pairData = selectedPair;
              // this.fetchAssetByCurrency(selectedPair.firstCurrencyId, 'firstCurrency')
              this.fetchAssetByCurrency(selectedPair.secondCurrencyId, 'secondCurrency')
              this.service.setTradePair(selectedPair)
              // this.marketPriceFacade.setSpotMarketPrice(selectedPair)
              // this.service.getMarketPrice(selectedPair._id)

              //   this.pairs = v.result.reduce((acc: any[], curr: any) => {
              //     if (!acc[curr.secondCurrencySymbol]) {
              //       acc[curr.secondCurrencySymbol] = [];
              //       this.currencies.push(curr.secondCurrencySymbol)
              //     }
              //     acc[curr.secondCurrencySymbol].push(curr);
              //     return acc;
              //   }, {});

              //   this.favPairData = v.result.filter((element: any) => this.favPairList.includes(element.tikerRoot))
              //   this.activeCurrency = this.currencies[0]
            }
          }
        },
        error: (err: any) => {
        }
      })
  }

  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.auth.signedIn)
      return
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }
}
