import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SigninComponent } from './views/signin/signin.component';
import { SignupComponent } from './views/signup/signup.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SecurityComponent } from './views/security/security.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { IdentificationComponent } from './views/identification/identification.component';
import { KycComponent } from './components/kyc/kyc.component';
import { BankInfoComponent } from './views/bank-info/bank-info.component';
import { AddBankComponent } from './views/add-bank/add-bank.component';
import { EditBankComponent } from './views/edit-bank/edit-bank.component';
import { HistoryComponent } from './views/history/history.component';
import { SupportTicketComponent } from './views/support-ticket/support-ticket.component';
import { EditProfileComponent } from './views/edit-profile/edit-profile.component';
import { MarketHomeComponent } from './views/market-home/market-home.component';
import { MarketInnerComponent } from './views/market-inner/market-inner.component';
import { NotificationComponent } from './views/notification/notification.component';
import { WalletComponent } from './views/wallet/wallet.component';
import { DepositCryptoComponent } from './views/deposit-crypto/deposit-crypto.component';
import { WithdrawCryptoComponent } from './views/withdraw-crypto/withdraw-crypto.component';
import { FeeHomeComponent } from './views/fee-home/fee-home.component';
import { FeeInnerComponent } from './views/fee-inner/fee-inner.component';
import { SpotComponent } from './views/spot/spot.component';
import { WithdrawFiatComponent } from './views/withdraw-fiat/withdraw-fiat.component';
import { DepositFiatComponent } from './views/deposit-fiat/deposit-fiat.component';
import { LaunchpadComponent } from './views/launchpad/launchpad.component';
import { LaunchpadDetailsComponent } from './views/launchpad-details/launchpad-details.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { ContactComponent } from './views/contact/contact.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { VerifyComponent } from './views/verify/verify.component';
import { DeactivationComponent } from './views/deactivation/deactivation.component';
import { DCABOTComponent } from './views/dcabot/dcabot.component';
import { SubscriptionPageComponent } from './views/subscription-page/subscription-page.component';
import { MyexchangeComponent } from './views/myexchange/myexchange.component';
import { GridbotComponent } from './views/gridbot/gridbot.component';
// Import guard
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CompleteProfileComponent } from './views/complete-profile/complete-profile.component';
import { ChangePhoneNumberComponent } from './views/change-phone-number/change-phone-number.component';
import { BuyCryptoComponent } from './views/buy-crypto/buy-crypto.component';
import { AppTradeChartComponent } from './components/spot/app-trade-chart/app-trade-chart.component';
import { AmlPageComponent } from './views/aml-page/aml-page.component';
// import { ProfileCheckGuard } from './core/guards/profilecheck.guard';

const routes: Routes = [
  // auth
  { path: 'signin', component: SigninComponent, canActivate: [AuthGuard], data: { type: 'auth' } },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuard], data: { type: 'auth' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard], data: { type: 'auth' } },
  { path: 'reset-password/:authToken', component: ResetPasswordComponent, canActivate: [AuthGuard], data: { type: 'auth' } },

  // private
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'wallet',
    component: WalletComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'identification',
    component: IdentificationComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'AML',
    component: AmlPageComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'kyc/:country',
    component: KycComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'change-phone-number',
    component: ChangePhoneNumberComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'bank-info',
    component: BankInfoComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'add-bank',
    component: AddBankComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'edit-bank/:id',
    component: EditBankComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },

  {
    path: 'history/:type',
    component: HistoryComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'support-ticket',
    component: SupportTicketComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'complete-profile',
    component: CompleteProfileComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'security',
    component: SecurityComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'deposit-crypto',
    component: DepositCryptoComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'deposit-crypto/:coin',
    component: DepositCryptoComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },

  {
    path: 'withdraw-crypto',
    component: WithdrawCryptoComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'withdraw-crypto/:coin',
    component: WithdrawCryptoComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },

  {
    path: 'fee-inner',
    component: FeeInnerComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'subscriptionpage',
    component: SubscriptionPageComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },
  {
    path: 'myexchange',
    component: MyexchangeComponent,
    canActivate: [AuthGuard],
    data: { type: 'private' },
  },

  {
    path: 'market-inner',
    component: MarketInnerComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'buy-crypto',
    component: BuyCryptoComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },

  // public
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'spot',
    component: SpotComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'spot/:tikerRoot',
    component: SpotComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'withdraw-fiat',
    component: WithdrawFiatComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'withdraw-fiat/:coin',
    component: WithdrawFiatComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'deposit-fiat',
    component: DepositFiatComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'deposit-fiat/:coin',
    component: DepositFiatComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'market-home',
    component: MarketHomeComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'launchpad',
    component: LaunchpadComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'launchpad-details/:id',
    component: LaunchpadDetailsComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'fee-home',
    component: FeeHomeComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'dcabot',
    component: DCABOTComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'dcabot/:tikerRoot',
    component: DCABOTComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'gridbot',
    component: GridbotComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'deactivation',
    component: DeactivationComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  {
    path: 'app-trade-chart/:pair/:theme',
    component: AppTradeChartComponent,
    canActivate: [AuthGuard],
    data: { type: 'public' },
  },
  // contact
  {
    path: 'verify/:type',
    component: VerifyComponent,
    canActivate: [AuthGuard],
    data: { type: 'contact' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
