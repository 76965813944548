import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { WalletService } from 'src/app/services/wallet.service';

// Import facade
import { PairFacade } from '../../../store/pair'
import { TradeAssetFacade } from 'src/app/store/trade-asset';
import { MarketPriceFacade } from 'src/app/store/marketprice';

// Import lib
import { Cookies } from 'src/app/lib/cookies';
import isEmpty from 'src/app/lib/isEmpty';

// Import config
import { spotSocket } from 'src/app/app.module';

// Import lib
import { convert, fraction, longDecimals, nFormatter, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-marketpair',
  templateUrl: './marketpair.component.html',
  styleUrls: ['./marketpair.component.css']
})
export class MarketpairComponent {
  @Input() tikerRoot: any

  toFixedDown = toFixedDown
  convert = convert
  truncateDecimals = truncateDecimals
  longDecimals = longDecimals
  fraction = fraction
  nFormatter = nFormatter

  pairs: any = {}
  currencies: any = []
  activeCurrency: string = '';
  pairData: any = {}
  favPairList: any
  favPairData: any = []
  searchPair: string = ''
  searchTerm: string = ''
  quoteCurrency: string = 'BTC'

  constructor(
    private service: SpotService,
    private walletService: WalletService,
    private pairFacade: PairFacade,
    private tradeAssetFacade: TradeAssetFacade,
    private router: Router,
    private cookies: Cookies,
    private spotSocket: spotSocket,
    private socketService: SocketService,
    private marketPriceFacade: MarketPriceFacade,
    private route: ActivatedRoute
  ) {
    // console.log(this.isMobileLayout, 'isMobileLayout')
  }

  ngOnInit() {
    this.getFavList()
    this.getPairList()
    this.spotSocket.on('marketPrice', this.marketPriceWs.bind(this))
    this.quoteCurrency = this.tikerRoot.split('_')[1]
  }
  
  // ngOnDestroy() {
  //   this.spotSocket.off('marketPrice');
  // }

  getFavList() {
    this.favPairList = localStorage.getItem('favPair');
    if (this.favPairList)
      this.favPairList = JSON.parse(this.favPairList)

    // console.log(this.favPairList, 'this.favPairList')
  }

  getPairList() {
    this.service.getPairList()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (isEmpty(this.tikerRoot)) {
              let pair = `${v.result[0].firstCurrencySymbol}_${v.result[0].secondCurrencySymbol}`
              this.router.navigateByUrl("/spot/" + pair)
            } else {
              this.pairFacade.setPair(v.result)
              const selectedPair = v.result.find((el: any) => el.tikerRoot === this.tikerRoot.replace('_', ''))

              this.pairData = selectedPair;
              this.fetchAssetByCurrency(selectedPair.firstCurrencyId, 'firstCurrency')
              this.fetchAssetByCurrency(selectedPair.secondCurrencyId, 'secondCurrency')
              this.service.setTradePair(selectedPair)
              this.marketPriceFacade.setSpotMarketPrice(selectedPair)
              // this.service.getMarketPrice(selectedPair._id)

              this.pairs = v.result.reduce((acc: any[], curr: any) => {
                if (!acc[curr.secondCurrencySymbol]) {
                  acc[curr.secondCurrencySymbol] = [];
                  this.currencies.push(curr.secondCurrencySymbol)
                }
                acc[curr.secondCurrencySymbol].push(curr);
                return acc;
              }, {});

              this.favPairData = v.result.filter((element: any) => this.favPairList.includes(element.tikerRoot))
              this.activeCurrency = this.currencies[0]
            }
          }
        },
        error: (err: any) => {
        }
      })
  }

  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.cookies.isLogin())
      return
    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }

  changePair(pair: any) {
    const newPair = pair.firstCurrencySymbol + '_' + pair.secondCurrencySymbol
    const activePair = this.route.snapshot.params['tikerRoot']
    if (newPair == activePair) return;

    this.socketService.emit("unSubscribe", activePair.replace("_", ""));

    this.router.navigateByUrl('/spot/' + newPair)
    this.service.setTradePair(pair)
    this.fetchAssetByCurrency(pair.firstCurrencyId, 'firstCurrency')
    this.fetchAssetByCurrency(pair.secondCurrencyId, 'secondCurrency')

    this.pairData = pair;
    this.marketPriceFacade.setSpotMarketPrice(pair)
  }

  marketPriceWs(result: any) {
    if (result.pairId == this.pairData._id) {
      this.marketPriceFacade.setSpotMarketPrice(result.data)
    }
  }

  addToFav(pair: any) {
    let favPair: any = JSON.parse(localStorage.getItem('favPair') || '[]');
    if (favPair.includes(pair.tikerRoot)) {
      favPair = favPair.filter((item: any) => item != pair.tikerRoot);
      this.favPairData = this.favPairData.filter((item: any) => item.tikerRoot !== pair.tikerRoot);
    }
    else {
      favPair.push(pair.tikerRoot);
      this.favPairData.push(pair);
    }
    localStorage.setItem('favPair', JSON.stringify(favPair));
    this.getFavList()
  }

  handleSearchPair(event: any) {
    this.searchPair = event.target.value.toUpperCase().replace('/', '')
  }

}
