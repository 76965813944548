import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import isEmpty from 'src/app/lib/isEmpty';
import { BotService } from 'src/app/services/bot.service';

@Component({
  selector: 'app-dcabotpreview',
  templateUrl: './dcabotpreview.component.html',
  styleUrls: ['./dcabotpreview.component.css']
})
export class DcabotpreviewComponent {
  @Input() botDetails: any
  @Input() orderPlaceDet: any
  showPrevious = false
  startBot = false
  closeBot = false
  constructor(
    private service: BotService,
    private toastr: ToastrService,
  ) {
  }
  ngOnInit() {
    console.log(this.botDetails, '-----11')
  }
  handlePrevious() {
    this.showPrevious = true
  }
  submitForm(reqData: any) {
    this.startBot = true
    this.service.dcaOrderPlace(reqData).subscribe({
      next: (v: any) => {
        this.startBot = false
        this.closeBot = true
        this.toastr.success(v.message)
      },
      error: (err: any) => {
        this.startBot = false
        console.log(err, '-------262')
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          if (!isEmpty(validationErrors)) {
            // this.toastr.error(err.error.message)
            if (err?.error?.errors?.order) {
              this.toastr.error(err.error.errors.order)
            }
          }
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
}
