import { Component, OnInit, inject } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserFacade } from '../../store/user';
import { Cookies } from 'src/app/lib/cookies';
import { encryptString } from 'src/app/lib/cryptoJs';
import { UserSettingFacade } from 'src/app/store/usersetting';
import isEmpty from 'src/app/lib/isEmpty';
interface Profile {
  aadharProof: string;
  panProof: string;
  aadharNumber: string;
  panNumber: string;
  phoneStatus: string,
  phoneNo: string,
  phoneCode: string,
  kycCountry: string,
  kycStatus: string,
  _id: string
}
@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.css'],
})
export class IdentificationComponent {
  private readonly userFacade: UserFacade = inject(UserFacade);

  phonesubmitted: boolean = false
  kycform!: FormGroup;
  aadharGroup!: FormGroup;
  panGroup!: FormGroup;
  verifyGroup!: FormGroup;
  phonegroup!: FormGroup;

  seconds: number = 0;
  isOtpSend: boolean = false;
  timerInSeconds: number = 60;
  phoneOtpserverErrors: any;
  otpLoader: boolean = false;

  serverErrors: any;
  optsubmitted: boolean = false;
  verifysubmitted: boolean = false;
  pansubmitted: boolean = false;
  otpstatus: boolean = false
  profileDetails: Profile = {
    aadharProof: '',
    panProof: '',
    aadharNumber: '',
    panNumber: '',
    phoneStatus: '',
    phoneNo: '',
    phoneCode: '',
    kycCountry: '',
    kycStatus: '',
    _id: ''
  };
  isPanLoading: boolean = false

  requestOtpLoader: boolean = false
  kycBtnLoader: any = false
  kycSeconds: number = 0
  kycTimerInSeconds: number = 40;

  countries: any = []
  selectedCountry: string = ''

  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private _fb: FormBuilder,
    // private route: ActivatedRoute,
    // private cookies: Cookies,
    // private userSettingFacade: UserSettingFacade
  ) { }

  ngOnInit() {
    this.getCountries()
    this.aadharGroup = this._fb.group({
      aadhar_number: ['', [Validators.required, Validators.pattern('^[0-9]{12,12}$')]],
      //email: ['', [Validators.required, Validators.email]]
    });
    this.panGroup = this._fb.group({
      aadhar_number: ['', [Validators.required, Validators.pattern('^[0-9]{12,12}$')]],
      pan_number: ['', [Validators.required, Validators.pattern('^\\S*$')]],
      //  city: ['', Validators.required]
    });
    this.verifyGroup = this._fb.group({
      aadhar_number: ['', [Validators.required, Validators.pattern('^[0-9]{12,12}$')]],
      ref_id: ['', Validators.required],
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]]
    });
    this.kycform = this._fb.group({
      aadharGroup: this.aadharGroup,
      panGroup: this.panGroup,
      verifyGroup: this.verifyGroup
    });
    //Redux call
    this.getprofile()
    this.phonegroup = this._fb.group({
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]],
    });
  }

  getCountries() {
    this.service.getKycCountries()
      .subscribe({
        next: (data: any) => {
          const { success, message, result } = data
          if (success) {
            this.countries = data.result
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.profileDetails.panProof = data.panProof
          this.profileDetails.aadharProof = data.aadharProof
          this.profileDetails.aadharNumber = data.aadharNumber
          this.profileDetails.panNumber = data.panNumber
          this.profileDetails.phoneStatus = data.phoneStatus
          this.profileDetails.phoneNo = data.phoneNo
          this.profileDetails.phoneCode = data.phoneCode
          this.profileDetails._id = data._id
          this.profileDetails.kycCountry = data.kycCountry
          this.profileDetails.kycStatus = data.kycStatus
          console.log(this.profileDetails, 'profileDetails')
        },
        error: (err: any) => {

        },
        complete: () => { }
      })
  }

  handleChangeCountry(event: any) {
    const { value } = event.target;
    if (!isEmpty(value) && value == 'IN') {
      this.selectedCountry = value

    }
    else {
      this.router.navigateByUrl(`/kyc/${value.toLowerCase()}`)
    }
  }

  Otpsubmit(type: any) {
    // console.log(this.aadharGroup.value, 'this.aadharGroup.value')
    this.optsubmitted = true;
    if (!this.aadharGroup.valid)
      return
    // this.otpstatus = true

    // this.verifyform.patchValue({
    //   aadhar_number: "123445556709",
    //   ref_id: "9923494294",
    // })
    this.requestOtpLoader = true

    this.service.sandboxkycotp(this.aadharGroup.value)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.requestOtpLoader = false
            this.optsubmitted = false
            this.toastr.success(v.message)
            this.otpstatus = true

            this.verifyGroup.patchValue({
              aadhar_number: v.data.aadhar_number,
              ref_id: v.data.ref_id,
            })


          }
        },
        error: (err: any) => {
          this.requestOtpLoader = false
          this.optsubmitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  VerifySubmit(type: any) {
    this.verifysubmitted = true;
    if (!this.verifyGroup.valid)
      return
    this.otpstatus = true

    this.service.sandboxkycverifyotp(this.verifyGroup.value)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.verifysubmitted = false
            this.toastr.success(v.message)

            setTimeout(function () {
              location.reload()
            }, 2000);

          }
        },
        error: (err: any) => {
          console.log(err, 'err')
          this.verifysubmitted = false

          // setTimeout(function () {
          //   this.kycBtnLoader = true
          //   // this.kycBtnLoader = false
          // }, 2000);


          // if (err.error.errors)
          //   this.serverErrors = err.error.errors || {};

          // console.log(err.error.message)
          // if (err.error.message)
          //   this.toastr.error(err.error.message)
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)

            // Load submit button for 40 seconds
            this.kyctimer(this.kycTimerInSeconds)
          }
        },
        complete: () => { }
      })
  }

  kyctimer(seconds: number) {
    this.kycBtnLoader = true
    this.kycSeconds = seconds;
    const kyctime = setInterval(() => {
      this.kycSeconds--;
      if (this.kycSeconds == 0) {
        this.kycBtnLoader = false
        clearInterval(kyctime);
      }
    }, 1000);
  }

  pansubmit(type: any) {
    this.pansubmitted = true;
    if (!this.panGroup.valid)
      return

    this.isPanLoading = true

    const reqData = {
      ...this.panGroup.value,
      pan_number: this.panGroup.value?.pan_number.toUpperCase()
    }
    this.service.sandboxkycpanadharlink(reqData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.pansubmitted = false
            this.isPanLoading = false
            this.toastr.success(v.message)
            location.reload()
          }
        },
        error: (err: any) => {
          this.pansubmitted = false
          this.isPanLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  resendOtp() {
    // console.log()
    // this.timer(this.timerInSeconds);
    this.otpLoader = true
    const reqData = {
      otpAuth: encryptString(this.profileDetails._id, true),
    }
    this.authService.resendOtpApi(reqData)
      .subscribe({
        next: (v: any) => {
          this.otpLoader = false
          this.timer(this.timerInSeconds);
          if (!this.isOtpSend) {
            this.isOtpSend = true;
          }
          this.toastr.success(v.message)
        },
        error: (err: any) => {
          this.otpLoader = false
          this.toastr.error(err.error.message)
        }
      })
  }

  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      if (this.seconds <= 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  handleChangePhoneotp() {
    this.phoneOtpserverErrors = {}
  }

  verifyPhoneNumber() {
    this.phonesubmitted = true

    if (this.phonegroup.invalid)
      return

    // console.log(this.phonegroup.value, 'this.phonegroup.value')
    const { otp } = this.phonegroup.value
    const reqData = {
      otpAuth: encryptString(this.profileDetails._id, true),
      otp
    }
    this.authService.verifyOtpApi(reqData)
      .subscribe({
        next: (v: any) => {
          this.phonesubmitted = false
          this.toastr.success(v.message)
          this.phonegroup.reset()
          this.phoneOtpserverErrors = {}
          this.getUser()
          // this.router.navigateByUrl(`/reset-password/${v.userToken}`)
        },
        error: (err: any) => {
          this.phonesubmitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.phoneOtpserverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        }
      })
  }

  getUser() {
    this.service.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: () => { }
      })
  }

  navigate(url: string) {
    this.router.navigateByUrl(url)
  }
}
