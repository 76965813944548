import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TradePairFacade } from 'src/app/store/bottradepair';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { Store } from '@ngrx/store';
import { BotService } from 'src/app/services/bot.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-dcabotaction',
  templateUrl: './dcabotaction.component.html',
  styleUrls: ['./dcabotaction.component.css'],
  animations: [
    trigger('contentAnimation', [

      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.5s ease-out', style({ height: '*', opacity: 1 }))
      ]),

      transition(':leave', [
        animate('.5s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})

export class DcabotactionComponent {
  isCollapsed = true;
  loader = false;
  isModalOpen = false;
  pairData: any = {}
  posDetails: any = {}
  constructor(
    private botFacade: TradePairFacade,
    private service: BotService,
    private toastr: ToastrService,
    private positionDetails: PositionDetailsFacade,
    private store: Store<{ positionDetails: PositionDetailsFacade }>
  ) { }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  ngOnInit() {
    this.getSinglePair()
    this.store.select('positionDetails').subscribe((posDet: any) => {
      this.posDetails = posDet.positionDetails;
    });
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
      },
      error: (err: any) => { }
    })
  }

  // Function to open the modal
  openModal(): void {
    this.isModalOpen = true;
  }

  // Function to close the modal
  closeModal(): void {
    this.isModalOpen = false;
  }

  cancelOrder() {
    this.loader = true
    // try {
    let reqData = {
      pairId: this.pairData._id,
      BotType: "GENIE",
      exchange: this.pairData.botstatus,
      positionId: this.posDetails?._id ? this.posDetails?._id : "",
    };

    console.log(reqData, '------68', this.pairData, this.posDetails)
    this.service.closeBot(reqData)
      .subscribe({
        next: (v: any) => {
          this.loader = false
          if (v.status) {
            this.toastr.success(v.message)
          } else {
            this.toastr.success(v.message)
          }
          this.closeModal()
          this.positionDetails.setPosition({})
        },
        error: (err: any) => {
          this.closeModal()
          this.loader = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          }
        }
      })
  }
}
