<app-header></app-header>
<div class="d-flex justify-content-center">
  <main class="main">
    <div class="container-fluid">
      <div class="myexchange-fixed-width">
        <div class="row">
          <div class="col-lg-12">
            <div class="dashbox">
              <h2 class="myexchange-title">My Exchanges</h2>
              <ul class="nav nav-tabs myexchange-tab" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <img
                      src="../../../assets/images/binancelogo.png"
                      alt=""
                      img="img-fluid"
                      class=""
                    />
                    <h6>BINANCE</h6>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <img
                      src="../../../assets/images/bybit.png"
                      alt=""
                      img="img-fluid"
                      class="bybit_img dark_img"
                    />
                    <img
                      src="../../../assets/images/bybit_light.png"
                      alt=""
                      img="img-fluid"
                      class="bybit_img light_img"
                    />
                  </button>
                </li>
              </ul>

              <div class="tab-content myxchange-tabcontent mt-5">
                <div
                  class="tab-pane active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="blue-box">
                    <div class="head">Notification</div>
                    <ul>
                      <li>
                        Please confirm that the API permissions has checked
                        "Enable Spot & Margin Trading" Do not check "Withdraw"
                        option.
                      </li>
                      <li>
                        Please enter the correct API please do not enter special
                        character.
                      </li>
                    </ul>
                  </div>
                  <div class="blue-box">
                    <div>
                      <img
                        src="../../../assets/images/alert_img.png"
                        alt=""
                        img="img-fluid"
                        class="me-2"
                      />
                      Your Binance API Key Binded
                    </div>
                  </div>
                  <div class="blue-box">
                    <div class="head">IP Group Binding (Optional)</div>
                    <div>
                      For security, DCA BOT recommends binding the server IP
                      address when creating the API. For users who need to bind
                      the IP address, simply copy the IP addresses from below
                      and paste it in the input box of Cred Exchange. Please
                      note that IP address binding is optional.
                    </div>
                    <div class="input-group mb-3 mt-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/copy_img.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="blue-box">
                    <div class="head">API Key</div>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/eye_icon.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                    <div class="head">Secret Key</div>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/eye_closed_icon.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                    <div class="head">Email Verification code</div>
                    <div class="dflex mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <button class="">Send Code</button>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        I understand the risks associated with using this API
                        binding
                      </label>
                    </div>
                  </div>

                  <div class="d-flex gap-4 button-exchange">
                    <button class="green-btn">Start now</button>
                    <button class="red-btn">Delete</button>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="blue-box">
                    <div class="head">Notification</div>
                    <ul>
                      <li>
                        Please confirm that the API permissions has checked
                        "Enable Spot & Margin Trading" Do not check "Withdraw"
                        option.
                      </li>
                      <li>
                        Please enter the correct API please do not enter special
                        character.
                      </li>
                    </ul>
                  </div>
                  <div class="blue-box">
                    <div>
                      <img
                        src="../../../assets/images/alert_img.png"
                        alt=""
                        img="img-fluid"
                        class="me-2"
                      />
                      Your Binance API Key Binded
                    </div>
                  </div>
                  <div class="blue-box">
                    <div class="head">IP Group Binding (Optional)</div>
                    <div>
                      For security, DCA BOT recommends binding the server IP
                      address when creating the API. For users who need to bind
                      the IP address, simply copy the IP addresses from below
                      and paste it in the input box of Cred Exchange. Please
                      note that IP address binding is optional.
                    </div>
                    <div class="input-group mb-3 mt-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/copy_img.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="blue-box">
                    <div class="head">API Key</div>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/eye_icon.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                    <div class="head">Secret Key</div>

                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <span class="input-group-text" id="basic-addon1">
                        <img
                          src="../../../assets/images/eye_closed_icon.png"
                          alt=""
                          img="img-fluid"
                          class="ms-3"
                        />
                      </span>
                    </div>
                    <div class="head">Email Verification code</div>
                    <div class="dflex mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="51.83.185.11 51.75.57.94 51.75.57.95 51.75.57.99 51.75.57.100"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <button class="">Send Code</button>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                      />
                      <label class="form-check-label" for="flexCheckDefault1">
                        I understand the risks associated with using this API
                        binding
                      </label>
                    </div>
                  </div>

                  <div class="d-flex gap-4 button-exchange">
                    <button class="green-btn">Start now</button>
                    <button class="red-btn">Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
