<div class="bot-order">
  <div class="bot_preview_between">
    <div>
      <div class="bot-order-head">
        <h4>Preview Grid Bot</h4>
        <button class="bot-clear">
          <img src="assets/images/botclear.svg" alt="close" title="close" />
        </button>
      </div>
      <div class="bot_preview_box_wrapper">
        <div>
          <div class="bot_preview_box">
            <div>
              <span>Exchange</span><span class="border_line"></span
              ><span>Binance</span>
            </div>
            <div>
              <span>Pair</span><span class="border_line"></span
              ><span>ZIL-USDT</span>
            </div>
            <div>
              <span>Investment</span><span class="border_line"></span
              ><span>30 USDT</span>
            </div>
            <div>
              <span>Profit currency</span><span class="border_line"></span
              ><span>USDT</span>
            </div>
          </div>
        </div>
        <div>
          <div class="bot_preview_box">
            <div>
              <span>Sell orders</span><span class="border_line"></span
              ><span>2.52 LDO</span>
            </div>
            <div>
              <span>Buy orders</span><span class="border_line"></span
              ><span>23.937 USDT</span>
            </div>
          </div>
        </div>
        <div>
          <div class="bot_preview_box">
            <div>
              <span>High price</span><span class="border_line"></span
              ><span>2.38</span>
            </div>
            <div>
              <span>Low price</span><span class="border_line"></span
              ><span>2.3</span>
            </div>
            <div>
              <span>Grid levels</span><span class="border_line"></span
              ><span>4</span>
            </div>
            <div>
              <span>Grid step</span><span class="border_line"></span
              ><span>0.8584478296546294%</span>
            </div>
          </div>
        </div>
        <div>
          <div class="bot_preview_box">
            <div>
              <span>Order size currency</span><span class="border_line"></span
              ><span>USDT</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bgcolor d-flex align-items-center gap-2">
      <button class="">
        <img
          src="../../../../assets/images/left_arrow.png"
          alt="Icon"
          class="img-fluid left_arrow"
        /></button
      ><button class="continue"><span>Start Bot</span></button>
    </div>
  </div>
</div>
