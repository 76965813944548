import { Component } from '@angular/core';
import { SpotService } from 'src/app/services/spot.service';

import { CurrencyFacade } from 'src/app/store/currency';
import { WalletFacade } from 'src/app/store/wallet';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

import * as $ from 'jquery';
import isEmpty from 'src/app/lib/isEmpty';
import { toFixed, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';

@Component({
  selector: 'app-deposit-crypto',
  templateUrl: './deposit-crypto.component.html',
  styleUrls: ['./deposit-crypto.component.css'],
})
export class DepositCryptoComponent {
  toFixed = toFixed;
  truncateDecimals = truncateDecimals
  toFixedDown = toFixedDown

  wallet: any = {};
  currency: any = {};
  choosenCurrency: string = 'BTC';
  selectedAsset: any = {};
  selectedCurrency: any = {};
  currencymodal: boolean = true;
  tokenData: any = {};
  orgCurr: any = [];
  networkType: any = []

  constructor(
    private currencyFacade: CurrencyFacade,
    private walletFacade: WalletFacade,
    private service: SpotService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const curr = this.route.snapshot.params['coin'];
    if (!isEmpty(curr)) {
      this.choosenCurrency = curr;
    }
  }
  ngOnInit() {
    this.currencyRedux();
  }

  currencyRedux() {
    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        this.currency = data;
        this.walletRedux();
        // this.assetList();
      },
      error: (err: any) => { },
    });
  }

  walletRedux() {
    this.walletFacade.wallet$.subscribe({
      next: (data: any) => {
        this.wallet = data;
        this.assetList();
        // this.tokenTypefetch(this.choosenCurrency)

        let asset = data.find((el: any) => el.coin == this.choosenCurrency);
        if (asset) {
          let assetData = asset?.tokenAddressArray.filter((el: any) => {
            const coin = this.currency.find((item: any) => item._id == el.currencyId);
            return coin.status == "active" && coin.depositStatus == "active"
          });
          this.networkType = assetData
        }
      },
      error: (err: any) => { },
    });
  }
  assetList() {
    if (this.wallet && this.currency) {
      let asset = this.wallet.find(
        (el: any) => el.coin == this.choosenCurrency
      );
      let curr = this.currency.find(
        (el: any) => el.coin == this.choosenCurrency
      );
      if (asset && curr) {
        // One-liner to remove duplicates based on a given property
        const uniqueArrayOfObjects = Object.values(
          this.currency.reduce((acc: any, obj: any) => {
            if (obj.status == 'active' && obj.depositStatus === 'active' && obj.type != 'fiat') {
              return { ...acc, [obj.coin]: obj };
            }
            return acc;
          }, {})
        );
        let filteredAsset = asset?.tokenAddressArray.filter((el: any) => {
          const coin = this.currency.find((item: any) => item._id == el.currencyId);
          return coin.status == "active" && coin.depositStatus == "active"
        })

        let assetData = filteredAsset?.[0];
        this.tokenData = assetData;
        this.selectedAsset = asset;
        this.selectedCurrency = curr;
        this.orgCurr = uniqueArrayOfObjects;
      }
    }
  }

  selectDropdown(coin: any) {

    this.tokenTypefetch(coin)

    this.choosenCurrency = coin;
    $('#ChooseCrypto #curmodalclose').click();
    this.assetList();
  }

  tokenTypefetch(coin: any) {

    let asset = this.wallet.find((el: any) => el.coin == coin);
    if (asset) {
      let filteredAsset = asset?.tokenAddressArray.filter((el: any) => {
        const coin = this.currency.find((item: any) => item._id == el.currencyId);
        return coin.status == "active" && coin.depositStatus == "active"
      })

      this.networkType = filteredAsset
    }
  }


  CopyText(value: any) {
    navigator.clipboard.writeText(value);
    this.toastr.success('Copied...');
  }

  tokenNetworkChange(event: any) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    let asset = this.wallet.find((el: any) => el.coin == this.choosenCurrency);
    if (asset) {
      let assetData = asset?.tokenAddressArray.find(
        (el: any) => el.tokenType === selectedValue
      );
      this.tokenData = assetData;
    }
  }
}
