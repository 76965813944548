export const dateHours = (dateTime: any) => {
    try {
        const startDate = new Date(dateTime);
        const endDate = new Date();
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
        const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(timeDiff / (1000 * 60 * 60));
        const diffMinutes = Math.floor(timeDiff / (1000 * 60));
        let formattedDuration: any = {};
        if (diffYears > 0) {
            formattedDuration.years = diffYears;
        }
        if (diffMonths >= 12) {
            formattedDuration.years = Math.floor(diffMonths / 12);
            formattedDuration.months = diffMonths % 12;
        } else {
            formattedDuration.months = diffMonths;
        }

        formattedDuration.days = diffDays % 30;
        formattedDuration.hours = diffHours % 24;
        formattedDuration.minutes = diffMinutes % 60;
        return formatDuration(formattedDuration);
    } catch (err) {
        return "";
    }
};
function formatDuration(duration: any) {
    let formattedString = "";

    if (duration.years) {
        formattedString += `${duration.years}y `;
    }

    if (duration.months) {
        formattedString += `${duration.months}m `;
    }

    if (duration.days) {
        formattedString += `${duration.days}d `;
    }

    if (duration.hours) {
        formattedString += `${duration.hours}h `;
    }

    if (duration.minutes >= 0) {
        formattedString += `${duration.minutes}min `;
    }

    return formattedString.trim();
}