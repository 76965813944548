<main class="main">
  <div class="container-fluid">
    <div>
      <h1>AML</h1>
    </div>
      <form class="auth_form signup_form" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <label>Occupation</label>
            <select class="sign_input" formControlName="occupation">
              <option value="" selected>Select Occupation</option>
              <option value="Employed">Employed</option>
              <option value="Employed">Bussiness</option>
              <option value="Student">Student</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <label>Source of Fund </label>
            <select class="sign_input" formControlName="source_of_income">
              <option value="" selected>Source of Fund</option>
              <option value="Salary">Salary</option>
              <option value="Investment">Investment</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <label>Scale of Income </label>
            <select class="sign_input" formControlName="scale_of_income">
              <option value="" selected>Scale of Income</option>
              <option value="Less than ₹5,00,000">Less than ₹5,00,000</option>
              <option value="₹5,00,000 - ₹10,00,000">₹5,00,000 - ₹10,00,000 </option>
              <option value="₹10,00,000 - ₹25,00,000">₹10,00,000 - ₹25,00,000</option>
              <option value="₹25,00,000 - ₹50,00,000">₹25,00,000 - ₹50,00,000</option>
              <option value="More than ₹50,00,000">More than ₹50,00,000</option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <label>Trading Experience</label>
            <select class="sign_input" formControlName="trading_exp">
              <option value="" selected>Select Trading Experience</option>
              <option value="Trading Experience">Trading Experience</option>
              <option value="Less Than 1 Year">Less Than 1 Year</option>
              <option value="1-2 Year">1-2 Year</option>
              <option value="2-5 Year">2-5 Year</option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <label>Tax Liability</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" formControlName="tax_liability" value="Yes">
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  formControlName="tax_liability"  value="No">
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="sign_group sign_group_input">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" formControlName="terms_condition" value="true">
              <label class="form-check-label" for="flexRadioDefault1">
                By completing and submitting this questionnaire, I confirm that the information provided is accurate and truthful to the best of my knowledge. I understand that providing false or misleading information may result in consequences as outlined by the policies of CredBull Exchange and applicable laws
              </label>
            </div>
           
          </div>
        </div>
        <button class="sign_btn" type="submit">
         Submit
        </button>
      </form>
  </div>
</main>