import { Component } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
@Component({
  selector: 'app-gridbotcreate',
  templateUrl: './gridbotcreate.component.html',
  styleUrls: ['./gridbotcreate.component.css']
})
export class GridbotcreateComponent {
  isChecked: boolean = false; 
  isChecked2: boolean = false;
  value: number = 100;
  options: Options = {
    floor: 0,
    ceil: 200
  };
  amountMultiplier: number = 100;
  amountMultiplierOptions: Options = {
    floor: 0,
    ceil: 200
  };
  stepMultiplier: number = 100;
  stepMultiplierOptions: Options = {
    floor: 0,
    ceil: 200
  };
}
