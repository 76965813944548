import { Component } from '@angular/core';

// Import service
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {

  content: string = ''
  title: string = ''

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getCmsContent()
  }

  getCmsContent() {
    this.commonService.getCmsPages('terms')
      .subscribe({
        next: (v: any) => {

          this.content = v.result?.content
          console.log("the content of data is" , v );
          
          this.title = v.result?.title
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

}
