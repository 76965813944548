import { Component } from '@angular/core';
import { SpotService } from 'src/app/services/spot.service';
import { PairFacade } from 'src/app/store/pair';
import { CurrencyFacade } from 'src/app/store/currency';
import { PriceCnvFacade } from 'src/app/store/pricecnv';
import { UserSettingFacade } from 'src/app/store/usersetting';
import { UserFacade } from 'src/app/store/user';
import { WalletFacade } from 'src/app/store/wallet';
import isEmpty from 'src/app/lib/isEmpty';

// import lib
import { toFixed, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { WalletService } from 'src/app/services/wallet.service';
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css'],
})
export class WalletComponent {
  toFixedDown = toFixedDown;
  toFixed = toFixed;
  truncateDecimals = truncateDecimals

  search: string = ''
  pairList: any = {};
  currency: any = {};
  usersettings: any = {};
  wallet: any = {};
  user: any = {};
  priceconversion: any = {};
  Assets: any = {};
  totalBalance: any = [0];
  cryptoBalance: number = 0;
  inrBalance: number = 0;
  inrCurrency: any = {};
  url: string = 'spot';
  loader = false;

  usdtOptions = {
    decimalPlaces: 2,
    prefix: '$ ',
    useEasing: true,
    // duration: 2
    scrollSpyOnce: true
  }
  
  inrOptions = {
    decimalPlaces: 2,
    prefix: '&#8377; ',
    useEasing: true,
    // duration: 2
    scrollSpyOnce: true
  }

  constructor(
    private pairFacade: PairFacade,
    private currencyFacade: CurrencyFacade,
    private priceCnvFacade: PriceCnvFacade,
    private userSettingFacade: UserSettingFacade,
    private userFacade: UserFacade,
    private walletFacade: WalletFacade,
    private service: SpotService,
    private walletService: WalletService
  ) { }

  ngOnInit() {
    this.userDeposit();
    this.makeAPICalls();
  }

  makeAPICalls() {
    this.priceCnvFacade.pricecnv$.subscribe({
      next: (priceconversionData) => {
        this.priceconversion = priceconversionData;
        this.userFacade.user$.subscribe({
          next: (userData) => {
            this.user = userData;

            this.userSettingFacade.usersetting$.subscribe({
              next: (usersettingData) => {
                this.usersettings = usersettingData;

                this.service.getPairList().subscribe({
                  next: (v: any) => {
                    if (v.success) {
                      this.pairFacade.setPair(v.result);
                      this.pairList = v.result;
                    }

                    this.currencyFacade.currency$.subscribe({
                      next: (currencyData) => {
                        this.currency = currencyData;

                        this.walletFacade.wallet$.subscribe({
                          next: (walletData) => {
                            this.wallet = walletData;

                            this.pairFacade.pair$.subscribe({
                              next: (pairData) => {
                                this.pairList = pairData;
                                this.assetList();
                              },
                              error: (err) => {
                                // console.log(err)
                              },
                            });
                          },
                          error: (err) => {
                            // console.log(err)
                          },
                        });
                      },
                      error: (err) => {
                        // console.log(err)
                      },
                    });
                  },
                  error: (err) => {
                    // console.log(err)
                  },
                });
              },
              error: (err) => {
                // console.log(err)
              },
            });
          },
          error: (err) => {
            // console.log(err)
          },
        });
      },
      error: (err) => {
        // console.log(err)
      },
    });
  }

  userDeposit() {
    this.walletService.userDeposit().subscribe({
      next: (v: any) => {
        if (v.success) {
        }
      },
      error: (err: any) => { },
    });
  }
  getPairList() {
    this.service.getPairList().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.pairFacade.setPair(v.result);
          this.pairList = v.result;
        }
      },
      error: (err: any) => { },
    });
  }

  handleSearch(event: any) {
    this.search = event.target.value.toUpperCase().replace('/', '')
  }

  assetList() {
    let assetArr = [];
    let priceCNV;
    let totalAmount = 0;
    let inrBalance = 0;
    let cryptoBalance = 0;

    if (this.wallet.length > 0 && this.currency.length > 0) {
      let wallets = this.wallet;
      // console.log(wallets, 'wallets')

      for (let item of wallets) {
        let bal = item.spotBal;
        let convertPrice = 1

        if (this.usersettings && this.priceconversion && this.priceconversion.length > 0) {
          const currencySymbol = this.usersettings.userSetting?.currencySymbol
          const defaultcurr = currencySymbol == 'USD' ? 'USDT' : currencySymbol

          if (item.coin != 'USDT') {
            priceCNV = this.priceconversion.find((el: any) =>
              el.baseSymbol == item.coin && el.convertSymbol == defaultcurr
            );
            convertPrice = parseFloat(priceCNV.convertPrice)
          }
        }

        let curr = this.currency.find((el: any) => el._id == item._id && el.status == "active");
        if (!isEmpty(curr)) {
          const hasTradePair = this.pairList &&
            this.pairList.find(
              (pair: any) =>
                (pair.status === 'active' &&
                  pair.firstCurrencySymbol === curr.coin) ||
                pair.secondCurrencySymbol === curr.coin
            );

          assetArr.push({
            wallet: item,
            currency: curr,
            hasTradePair: hasTradePair,
          });
        }

        if (!isEmpty(curr) && (!isEmpty(priceCNV) || item.coin == 'USDT')) {
          if (!isEmpty(bal) && bal > 0) {
            let amount = bal * convertPrice;
            totalAmount = totalAmount + amount;
            if (['crypto', 'token'].includes(curr.type)) {
              cryptoBalance += amount;
            } else if (curr.type == 'fiat' && curr.coin == 'INR') {
              inrBalance = bal;
            }
          }
          if (curr.type == 'fiat' && curr.coin == 'INR') {
            this.inrCurrency = curr
          }
        }

        // console.log(assetArr, 'assetArr')
        this.Assets = assetArr;
      }
      this.totalBalance = truncateDecimals(totalAmount, 2);
      this.cryptoBalance = truncateDecimals(cryptoBalance, 2);
      this.inrBalance = truncateDecimals(inrBalance, 2);
      this.loader = true
    }
  }
}
