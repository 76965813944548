import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmlServiceService } from 'src/app/services/aml.service.service';
import { UserFacade } from 'src/app/store/user/user.facade';

@Component({
  selector: 'app-aml-page',
  templateUrl: './aml-page.component.html',
  styleUrls: ['./aml-page.component.css']
})
export class AmlPageComponent implements OnInit {
  form!: FormGroup;
  private readonly userFacade: UserFacade = inject(UserFacade);
  userId!: string;
  user!: string;

  constructor(private amlService: AmlServiceService, private fb: FormBuilder) {
    this.form = this.fb.group({
      occupation: [''],
      source_of_income: [''],
      scale_of_income: [''],
      user: [''], // Add userId if needed
      userId: [''], // Add userId if needed
      trading_exp: [''],
      tax_liability: [''],
      terms_condition: [''], // Checkbox should be initially unchecked
    });
  }

  ngOnInit(): void {
    // You can initialize any data or perform actions on component initialization here
  }

  submitForm(): void {
    if (this.form.valid) {
      this.userFacade.user$.subscribe({
        next: (userData) => {
          
          this.userId = userData._id;
          this.user = userData.userId;
          
          // Update the form values with userId and user
          this.form.patchValue({
            userId: this.userId,
            user: this.user
          });
          console.log("userFacade" , this.form.value);

          // Make the API call after updating the form values
          this.amlService.addAmldata(this.form.value).subscribe({
            next: (response: any) => {
              console.log('amldata:', response);
              // Reset form after successful submission if needed
              this.form.reset();
              // You can also add any success message or redirection logic here
            },
            error: (err: any) => {
              console.error('Error:', err);
              // Handle error messages or display to user
            }
          });
        },
        error: (error) => {
          console.error('Error fetching user data:', error);
        }
      });
    } else {
      // Form is invalid, handle validation messages or prevent submission
      console.error('Form is invalid');
    }
  }
}
