import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Renderer2, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { detect } from 'detect-browser';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';

// Import service
import { AuthService } from 'src/app/services/auth.service';

// Import facade
import { AuthFacade } from 'src/app/store/auth';
import { UserFacade } from 'src/app/store/user';
import { UserSettingFacade } from 'src/app/store/usersetting';
import { WalletService } from 'src/app/services/wallet.service';
import { WalletFacade } from 'src/app/store/wallet';
import { SocketService } from 'src/app/services/socket.service';
import { CommonService } from 'src/app/services/common.service';
import { PriceCnvFacade } from 'src/app/store/pricecnv';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {

  phoneNumber: any
  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  isLoading: boolean = false
  is2FAEnabled: boolean = false
  count: any = 'send'
  loaders: any = false
  isOtpSend: boolean = false
  otpVerified: boolean = false;
  showVideoModal:boolean = false
  showPassword: boolean = false
  private subscription?: Subscription;

  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly userFacade: UserFacade = inject(UserFacade);
  private readonly userSettingFacade: UserSettingFacade = inject(UserSettingFacade);
  private readonly walletFacade: WalletFacade = inject(WalletFacade);

  otpToken: string = '';
  otpTextBox: boolean = false;
  seconds: number = 180;
  timerInSeconds: number = 180;
  rememberMeEmail: boolean = false;
  rememberMeNewPhoneNo: boolean = false;

  loginHistory = {}
  isDiv1Visible = true;
  isDiv2Visible = false;

  reCaptcha: any;

  constructor(
    private http: HttpClient,
    private _fb: FormBuilder,
    private service: AuthService,
    private walletService: WalletService,
    private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private socketService: SocketService,
    private commonService: CommonService,
    private priceCnvFacade: PriceCnvFacade,
    private renderer: Renderer2
  ) {
    const getemail = this.cookieService.get('email') || '';
    const getpassword = this.cookieService.get('password') || '';
    this.rememberMeEmail = getemail !== '';

    this.form = this._fb.group({
      // email: [getemail || '', Validators.required],
      email: [getemail || '', [Validators.required, Validators.pattern(this.emailPattern)]],

      newPhoneNo: [''],
      newPhoneCode: [''],
      password: [getpassword || '', [Validators.required]],
      otp: [''],
      reCaptcha: [''],
      twoFACode: ['']
    });
  }

  ngOnInit() {
    this.getGeoLocation()
    //this.getToken()
    // this.reCaptcha = setInterval(() => {
    //   this.getToken()
    // }, 8000);
    //this.isLoading = true;
    // setInterval(() => {
    //   this.isLoading = false;
    // }, 1500);
  }

  ngOnDestroy() {
    if (this.reCaptcha) {
      clearInterval(this.reCaptcha);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  showDiv1() {
    this.submitted = false;
    this.serverErrors = {};

    const getemail = this.cookieService.get('email') || '';
    const getpassword = this.cookieService.get('password') || '';

    this.form.patchValue({
      email: getemail,
      newPhoneNo: '',
      password: getpassword
    })
    const emailControl = this.form.get('email');
    const phoneControl = this.form.get('newPhoneNo');
    const validators = [Validators.required];
    if (emailControl) {
      emailControl.addValidators(validators);
      emailControl.updateValueAndValidity();
    }
    if (phoneControl) {
      phoneControl.removeValidators(validators);
      phoneControl.updateValueAndValidity();
    }

    this.isDiv1Visible = true;
    this.isDiv2Visible = false;
    this.reset()
  }

  showDiv2() {
    this.submitted = false;
    this.serverErrors = {};

    const getNewPhoneNo = this.cookieService.get('newPhoneNo') || '';
    const getNewPhoneCode = this.cookieService.get('newPhoneCode') || '';
    const getpassword = getNewPhoneNo ? this.cookieService.get('password') : '';
    this.rememberMeNewPhoneNo = getNewPhoneNo !== '';

    this.form.patchValue({
      email: '',
      newPhoneNo: getNewPhoneNo,
      newPhoneCode: getNewPhoneCode,
      password: getpassword,
      otp: ''
    })
    const emailControl = this.form.get('email');
    const phoneControl = this.form.get('newPhoneNo');
    const validators = [Validators.required];
    if (phoneControl) {
      phoneControl.addValidators(validators);
      phoneControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.removeValidators(validators);
      emailControl.updateValueAndValidity();
    }

    this.isDiv1Visible = false;
    this.isDiv2Visible = true;
    this.form.controls['newPhoneNo']?.enable();
    this.reset()
  }

  reset() {
    this.submitted = false
    this.otpToken = '';
    this.otpTextBox = false;
    this.seconds = 60;
    this.loaders = false
    this.is2FAEnabled = false
    this.isOtpSend = false
    this.otpVerified = false;
  }

  // getToken() {
  //   this.subscription = this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token: string) => {
  //       this.form.patchValue({
  //         reCaptcha: token
  //       })
  //     });
  // }

  getGeoLocation() {
    const browser = detect();
    fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((v) => {
        this.loginHistory = {
          countryName: v.country_name,
          countryCode: v.country_calling_code,
          ipaddress: v.ip,
          region: v.region,
          broswername: browser?.name,
          ismobile: false,
          os: browser?.os
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  get email() { return this.form.get('email') }

  get newPhoneNo() { return this.form.get('newPhoneNo') }

  get phone() { return this.form.get('phone') }

  get password() { return this.form.get('password') }

  get otp() { return this.form.get('otp') }

  get twoFACode() { return this.form.get('twoFACode') }

  submitForm() {
    // console.log(this.form, 'this.form')
    // console.log(this.form.valid, 'this.form.valid')
    this.submitted = true;
    if (!this.form.valid || this.isLoading == true)
      return

    this.isLoading = true

    this.subscription = this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        const { newPhoneCode, newPhoneNo } = this.form.value
        const reqData = {
          ...this.form.value,
          newPhoneNo: newPhoneNo.replace(newPhoneCode, ''),
          roleType: this.isDiv1Visible ? 1 : 2,
          loginHistory: this.loginHistory,
          // count: this.count,
          isOtpSend: this.isOtpSend,
          reCaptcha: token
        }

        this.service.loginApi(reqData)
          .subscribe({
            next: (v: any) => {
              if (v.status == "SUCCESS") {
                this.submitted = false

                this.authFacade.onSignInSuccess({ token: v.token });
                this.userFacade.setUser(v.result);
                this.userSettingFacade.setUserSetting(v.userSetting);
                this.cookieService.set('user_token', v.token, { path: '/' })
                // this.socketService.createSocketUser('123')

                this.toastr.success(v.message)
                this.router.navigateByUrl("/spot/CRED_INR")

                this.serverErrors = {}

                if (this.isDiv1Visible) {
                  if (this.rememberMeEmail) {
                    this.cookieService.set('email', this.form.value.email);
                    this.cookieService.set('password', this.form.value.password);
                  } else {
                    this.cookieService.delete('email');
                    if (this.cookieService.get('newPhoneNo') === '') {
                      this.cookieService.delete('password');
                    }
                  }
                } else {
                  if (this.rememberMeNewPhoneNo) {
                    this.cookieService.set('newPhoneNo', this.form.value.newPhoneNo);
                    this.cookieService.set('newPhoneCode', this.form.value.newPhoneCode);
                    this.cookieService.set('password', this.form.value.password);
                  } else {
                    this.cookieService.delete('newPhoneNo');
                    this.cookieService.delete('newPhoneCode');
                    if (this.cookieService.get('email') === '') {
                      this.cookieService.delete('password');
                    }
                  }
                }

                this.form.reset()
                this.apiCall()

              } else if (v.status === 'OTP') {
                this.isLoading = false
                this.loaders = true
                this.timer(this.timerInSeconds);
                // this.form.controls['newPhoneNo']?.disable();
                this.toastr.success(v.message)
                this.otpToken = v.userToken
                this.otpTextBox = true
                this.isOtpSend = true
              } else if (v.status === 'TWO_FA') {
                this.isLoading = false
                this.otpVerified = true
                this.submitted = false
                this.is2FAEnabled = true
                this.toastr.success(v.message)
              }
            },
            error: (err: any) => {
              // console.log(err, 'err')
              this.isLoading = false
              this.submitted = false
              if ([400, 404].includes(err.status)) {
                if (err.error.errors) {
                  const validationErrors = err.error.errors;
                  this.serverErrors = validationErrors || {};
                } else {
                  this.toastr.error(err.error.message)
                }
              } else if (err.status === 500) {
                this.toastr.error(err.error.message)
              }
            }
          })


      });
  }
  apiCall() {
    this.walletService.getAssetData().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.walletFacade.setWallet(v.result);
        }
      },
      error: err => { }
    })
    this.commonService.getPriceCnv().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.priceCnvFacade.setPriceCnv(v.result);
        }
      },
      error: () => { }
    })
  }

  onInputChange() {
    this.serverErrors = {}
  }

  countryChangedEvent(event: any) {
    this.form.patchValue({
      newPhoneCode: '+' + event.dialCode
    })
  }

  know_SignUp(){
    this.showVideoModal = !this.showVideoModal

  }

  resendOtp() {
    const reqData = {
      roleType: this.isDiv1Visible ? 1 : 2,
      otpAuth: this.otpToken,
    }
    this.service.resendOtpApi(reqData)
      .subscribe({
        next: (v: any) => {
          this.loaders = true
          this.timer(this.timerInSeconds);
          // this.count = 'resend'
          this.toastr.success(v.message)
          this.serverErrors = {}
        },
        error: (err: any) => {
          this.toastr.error(err.error.message)
        }
      })
  }

  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.loaders = false
        clearInterval(timer);
      }
    }, 1000);
  }

  verifyOtp() {
    this.submitForm()
    // const { otp } = this.form.value
    // const reqData = {
    //   ...this.form.value,
    //   otpAuth: this.otpToken,
    //   otp,
    //   // optType: 'login',
    //   // loginHistory: this.loginHistory
    // }
    // this.isLoading = true
    // this.service.verifyOtpApi(reqData)
    //   .subscribe({
    //     next: (v: any) => {
    //       this.isLoading = false
    //       this.authFacade.onSignInSuccess({ token: v.token });
    //       this.userFacade.setUser(v.result);
    //       this.userSettingFacade.setUserSetting(v.userSetting);
    //       this.cookieService.set('user_token', v.token)

    //       this.toastr.success(v.message)
    //       this.router.navigateByUrl("/dashboard")
    //       this.form.reset()
    //       this.serverErrors = {}
    //       this.apiCall()
    //     },
    //     error: (err: any) => {
    //       this.isLoading = false
    //       if ([400, 404].includes(err.status)) {
    //         const validationErrors = err.error.errors;
    //         this.serverErrors = validationErrors || {};
    //       } else if (err.status === 500) {
    //         this.toastr.error(err.error.message)
    //       }
    //     }
    //   })
  }

  hanldeShowPassword() {
    this.showPassword = !this.showPassword
  }

  handleRememberMeEmail(event: any) {
    this.rememberMeEmail = event.target.checked;
  }

  handleRememberMeNewPhoneNo(event: any) {
    this.rememberMeNewPhoneNo = event.target.checked;
  }


}
