import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setPosition, reset } from './position-details.actions';
import { selectTradeAsset } from './position-details.selectors';
import { Observable } from 'rxjs';
import { TradeAssetState } from './position-details.state';

@Injectable({ providedIn: 'root' })
export class PositionDetailsFacade {
    private readonly store: Store = inject(Store);

    readonly positionDetails$: Observable<TradeAssetState> = this.store.select(selectTradeAsset);

    setPosition(payload: any): void {
        this.store.dispatch(setPosition({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}