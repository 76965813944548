<div class="create-bot-head">
  <div class="bot-order-head">
    <h4>Create DCA Bot</h4>
  </div>
  <div class="create-bot">
    <img
      src="../../../../assets/images/startup.png"
      alt="start"
      title="start"
    />
    <!-- <img
      src="../../../../assets/images/completed.png"
      alt="start"
      title="start"
    /> -->
    <h6>Starting Up</h6>
    <p>Loading......</p>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        aria-valuenow="70"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 70%"
      >
        <span class="sr-only">70% Complete</span>
      </div>
    </div>
  </div>
</div>
