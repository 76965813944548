<div class="abv_flx">
  <span class="exchange">Exchange</span>
  <div class="drpdwn_box">
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="assets/images/binance.svg" class="img-fluid" alt="icon" />
        Binance
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <a class="dropdown-item" role="button" href="javascript:void(0)"
            ><img
              src="assets/images/binance.svg"
              class="img-fluid"
              alt="icon"
            />
            Binance</a
          >
        </li>
        <li>
          <a class="dropdown-item" role="button" href="javascript:void(0)"
            ><img src="assets/images/bybit.svg" class="img-fluid" alt="icon" />
            Bybit</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="coin_details zindex">
  <div class="card trade_card">
    <div class="card-header">
      <h2>Pairs</h2>
    </div>
    <div class="card-body">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="strategies-tab-pane"
          role="tabpanel"
          aria-labelledby="strategies-tab"
          tabindex="0"
        >
          <ul class="nav nav-tabs bottab mt-2" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="coin_one-tab"
                type="button"
                data-bs-toggle="tab"
                data-bs-target="#coin_one"
                role="tab"
                aria-controls="coin_one"
                aria-selected="true"
              >
                DCA
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="coin_two-tab"
                type="button"
                data-bs-toggle="tab"
                data-bs-target="#coin_two"
                role="tab"
                aria-controls="coin_two"
                aria-selected="false"
              >
                GRID
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="strategy_filter">
              <div class="search_inp_div">
                <input
                  type="text"
                  placeholder="Search Pair"
                  class="form-control search_inp"
                  name="symbol"
                  autocomplete="off"
                  value=""
                />
                <i class="bi bi-search"></i>
              </div>
              <button
                class="settings_button"
                data-bs-toggle="modal"
                data-bs-target="#filter_bot_modal"
              >
                <i class="bi bi-sliders2"></i>
              </button>
            </div>
            <div
              class="tab-pane fade show"
              id="coin_two"
              role="tabpanel"
              aria-labelledby="coin_two-tab"
            >
              <div class="pair_table pair_table_chg">
                <div class="pair_table_head">
                  <div>Pair</div>
                  <div>Price</div>
                  <div>24H change</div>
                </div>
                <div class="pair_table_body">
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow active">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>BTC<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap">
                      <small>107413.49</small>
                    </div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              3.322
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img src="assets/images/binance.svg" alt="Binance" />
                        <h3>BOND<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="greenTxt d-flex price-status">
                              44.527
                              <span class="pair_uparrow"
                                ><img
                                  src="assets/images/uparrow.svg"
                                  alt="Up"
                                  title="Up"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pair_table_bodyrow">
                    <div class="pair_table_bodyrow_top">
                      <div>
                        <img
                          src="assets/images/binance.svg"
                          alt="Binance"
                          title="Binance"
                        />
                        <h3>GFT<span>/ USDT</span></h3>
                      </div>
                    </div>
                    <div class="direction_badge_wrap"><small>0</small></div>
                    <div class="pair_table_bodyrow_bottom">
                      <div class="pair_table_bodyrow_bottom_right">
                        <div>
                          <span>
                            <small class="redText d-flex price-status">
                              -67.603
                              <span class="pair_downarrow"
                                ><img
                                  src="assets/images/downarrow.svg"
                                  alt="down"
                                  title="down"
                              /></span>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
