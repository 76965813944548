import { Component, HostListener, Renderer2, inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

// Import service
import { UserService } from './services/user.service';
import { WalletService } from './services/wallet.service';
import { CommonService } from './services/common.service';

// Import facade
import { UserFacade } from './store/user';
import { AuthFacade } from './store/auth';
import { UserSettingFacade } from './store/usersetting';
import { WalletFacade } from './store/wallet';
import { CurrencyFacade } from './store/currency';
import { PriceCnvFacade } from './store/pricecnv';
import { JwtWebTokenService } from './services/jwt-web-token.service';
import isEmpty from './lib/isEmpty';
import { spotSocket, userSocket } from './app.module';
import { ToastrService } from 'ngx-toastr';
import { SpotService } from './services/spot.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SocketService } from './services/socket.service';
import { TitleService } from './services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cred Bull';
  showHead: boolean = false;
  isProfileUpdated: boolean | string = '';

  private readonly userFacade: UserFacade = inject(UserFacade);
  private readonly userSettingFacade: UserSettingFacade = inject(UserSettingFacade);
  private readonly walletFacade: WalletFacade = inject(WalletFacade);
  private readonly currencyFacade: CurrencyFacade = inject(CurrencyFacade);
  private readonly priceCnvFacade: PriceCnvFacade = inject(PriceCnvFacade);

  constructor(
    private router: Router,
    private userService: UserService,
    private walletService: WalletService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private jwtWebTokenService: JwtWebTokenService,
    private renderer: Renderer2,
    private userSocket: userSocket,
    private toastr: ToastrService,
    private spotSocket: spotSocket,
    private jwtHelperService: JwtHelperService,
    private socketService: SocketService,
    private titleService: TitleService
  ) {

  }

  ngOnInit(): void {
    // localStorage.setItem('theme', 'dark')
    const token = this.cookieService.get('user_token')
    if (token) {
      this.jwtWebTokenService.decodeJwt(token)
      this.getUser()
      this.getUserSetting()
      this.getAsset()
    } else {
      let theme = localStorage.getItem('theme')
      if (!theme) {
        localStorage.setItem('theme', 'dark')
        theme = 'dark'
      }
      this.userSettingFacade.updateTheme({ theme });
      if (theme === 'light') {
        this.renderer.addClass(document.body, 'light_theme');
      }
    }
    this.getPriceCnv()
    this.getCurrency()
    this.getSiteSetting()
    this.userSocket.on('FORCE_LOGOUT', (result: any) => {
      const token = this.cookieService.get('user_token')
      if (result && token != result) {
        this.cookieService.delete('user_token');
        this.router.navigate(['signin']);
      }
    })


    this.getUserFacade()
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        const noHeadUrls = [
          '/signin',
          '/signup',
          '/forgot-password',
          '/reset-password',
          '/',
          '/market-home',
          '/fee-home',
          '/launchpad',
          '/launchpad-details',
          '/about-us',
          '/spot',
          '/dcabot',
          '/gridbot',
          '/contact',
          '/privacy-policy',
          '/terms',
          '/verify/register',
          '/complete-profile',
          '/deactivation',
          '/subscriptionpage'

        ];

        if (noHeadUrls.includes(event['url']) || /^\/spot\/.+/.test(event['url']) || /^\/dcabot\/.+/.test(event['url']) || /^\/app-trade-chart\/.+/.test(event['url']) || /^\/verify\/.+/.test(event['url']) || /^\/launchpad-details\/.+/.test(event['url']) || /^\/reset-password\/.+/.test(event['url'])) {
          this.showHead = false;
        } else {
          this.showHead = true;
          if (this.isProfileUpdated !== '' && this.isProfileUpdated == false) {
            this.toastr.info("Complete your profile. We'll need a bit more information before you go to dashboard")
          }
          if (this.isProfileUpdated !== '' && this.isProfileUpdated == false && event['url'] !== '/edit-profile') {
            this.router.navigate(['/edit-profile'])
          }
        }
      }
    });
    this.userSocket.on("disconnect", () => {
      // console.log('User Socket Disconnected')
      const token = this.cookieService.get('user_token')
      if (token) {
        const decodedToken = this.jwtHelperService.decodeToken(token);
        if (decodedToken && decodedToken._id)
          this.socketService.createSocketUser(decodedToken._id)
      }
    });

    this.spotSocket.on("disconnect", () => {
      // console.log('Spot Socket Disconnected')
      const token = this.cookieService.get('user_token')
      if (token) {
        const decodedToken = this.jwtHelperService.decodeToken(token);
        if (decodedToken && decodedToken._id)
          this.socketService.createSocketUser(decodedToken._id)
      }
    });
  }

  getUser() {
    this.userService.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: () => { },
        complete: () => {
        }
      })
  }

  getUserFacade() {
    this.userFacade.user$
      .subscribe({
        next: (v: any) => this.isProfileUpdated = v.isProfileUpdated,
        error: () => { }
      })
  }

  getUserSetting() {
    this.userService.getUserSettingApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userSettingFacade.setUserSetting(v.result);
            localStorage.setItem('theme', v.result.theme)
            this.userSettingFacade.updateTheme({ theme: v.result.theme });
          }
        },
        error: () => { }
      })
  }

  getAsset() {
    this.walletService.getAssetData().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.walletFacade.setWallet(v.result);
        }
      },
      error: () => { }
    })
  }

  getCurrency() {
    this.commonService.getCurrency().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.currencyFacade.setCurrency(v.result);
        }
      },
      error: () => { }
    })
  }

  getPriceCnv() {
    this.commonService.getPriceCnv().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.priceCnvFacade.setPriceCnv(v.result);
        }
      },
      error: () => { }
    })
  }

  getSiteSetting() {
    this.userService.apigetSiteSetting()
      .subscribe({
        next: (v: any) => {
          this.userSettingFacade.setSiteSetting(v.result);
        },
        error: (err) => { }
      })
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const elementExists = document.getElementsByClassName('country-search').length
    if (elementExists) {
      const element = this.renderer?.selectRootElement('.country-search');
      const x = element as HTMLElement;
      setTimeout(() => x.focus(), 200);
    }
  }

}
