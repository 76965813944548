import { Component } from '@angular/core';

@Component({
  selector: 'app-myexchange',
  templateUrl: './myexchange.component.html',
  styleUrls: ['./myexchange.component.css']
})
export class MyexchangeComponent {

}
