import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent {

  // form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;
  plan: any;
  actTab: any
  subsData: any
  purchaseStatus: any
  planDet: any
  purchaseDet: any

  constructor(
    private service: SubscriptionService,
    private toastr: ToastrService,
  ) {

  }
  ngOnInit() {
    this.getPlan();
    this.userPlan()
  }
  setActTab(tabId: string) {
    this.actTab = tabId;
    this.getSusbcription(tabId)
  }
  parseAmount(value: any) {
    console.log(value, '------38')
    return parseInt(value, 10);  // The second argument 10 specifies the radix (decimal)
  }
  getPlan() {
    this.service.getSubsPlans().subscribe({
      next: (v: any) => {
        const planArr: any[] = []
        this.actTab = v?.result[0]?.plan
        this.getSusbcription(v?.result[0]?.plan)
        v?.result.filter(function (item: any, i: any) {
          let val = i == 0 ? `${item.plan} Month` : `${item.plan} Months`
          let value = { plan: item.plan, value: val }
          planArr.push(value)
        })
        this.plan = planArr;
      },
      error: (err: any) => {
        this.submitted = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  userPlan() {
    this.service.getUserPlan().subscribe({
      next: (v: any) => {
        let currentDate = new Date()
        let result = v?.result
        console.log(this.subsData, '-------67', v.result, currentDate)
        if (!result || new Date(result.expiryDate) < currentDate) {
          this.purchaseStatus = "not_purchased"
        } else {
          this.purchaseDet = result
          this.purchaseStatus = "purchased"
        }
      },
      error: (err: any) => {
        this.submitted = false;
        if ([400, 404].includes(err.status)) {
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  firstCapital = (value: string) => {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
  getSusbcription(tabId: any) {
    this.service.getSubscription({ plan: tabId }).subscribe({
      next: (v: any) => {
        this.subsData = v?.result
      },
      error: (err: any) => {
        this.submitted = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  setData(item: any) {
    this.planDet = item
  }
  submitForm() {
    this.submitted = true
    this.service.purchasePlan(this.planDet)
      .subscribe({
        next: (v: any) => {
          $("#startOrderModalBtn").click()
          this.submitted = false
          if (v.status) {
            this.toastr.success(v.message)
            this.userPlan()
            this.getPlan()
          } else {
            this.toastr.success(v.message)
          }
        },
        error: (err: any) => {
          $("#startOrderModalBtn").click()
          this.submitted = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          } else if (err.error.errors) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          }
        }
      })
  }
  upgradeForm() {
    this.submitted = true
    this.service.upgradePlan(this.planDet)
      .subscribe({
        next: (v: any) => {
          $("#upgradeOrderModalBtn").click()
          this.submitted = false
          if (v.status) {
            this.toastr.success(v.message)
            this.userPlan()
          } else {
            this.toastr.success(v.message)
          }
        },
        error: (err: any) => {
          $("#upgradeOrderModalBtn").click()
          this.submitted = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          } else if (err.error.errors) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          }
        }
      })
  }
}
