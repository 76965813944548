<!-- <div class="noActiveBots">
    <img src="assets/images/noactivebots.svg" class="img-fluid" alt="icon" />
    <p>You don’t have active bots</p>
    <button class="btn startButton">Start NewBot</button>
</div> -->
<div class="bot-order">
  <div class="bot-order-head">
    <h4>Create DCA Bot</h4>
    <button class="bot-clear">
      <img src="assets/images/botclear.svg" alt="close" title="close" />
    </button>
  </div>
  <div class="bot-order-select">
    <span class="exchange">Exchange</span>
    <div class="drpdwn_box">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="assets/images/binance.svg" class="img-fluid" alt="icon" />
          Binance
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropSearch">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Exchange Name"
              />
              <span class="input-group-text"><i class="bi bi-search"></i></span>
            </div>
          </div>
          <ul>
            <li>
              <a class="dropdown-item" role="button" href="javascript:void(0)"
                ><img
                  src="assets/images/binance.svg"
                  class="img-fluid"
                  alt="icon"
                />
                Binance</a
              >
            </li>
            <li>
              <a class="dropdown-item" role="button" href="javascript:void(0)"
                ><img
                  src="assets/images/bybit.svg"
                  class="img-fluid"
                  alt="icon"
                />
                Bybit</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="bot-order-select">
    <span class="exchange">Pairs</span>
    <div class="drpdwn_box">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          BTC/USDT
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropSearch">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search Pair"
              />
              <span class="input-group-text"><i class="bi bi-search"></i></span>
            </div>
          </div>
          <div class="pair_table pair_table_chg">
            <div class="pair_table_head">
              <div>Pair</div>
              <div>Price</div>
              <div>24H change</div>
            </div>
            <div class="pair_table_body">
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow active">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>BTC<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>107413.49</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          3.322
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img src="assets/images/binance.svg" alt="Binance" />
                    <h3>BOND<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="greenTxt d-flex price-status">
                          44.527
                          <span class="pair_uparrow"
                            ><img
                              src="assets/images/uparrow.svg"
                              alt="Up"
                              title="Up"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pair_table_bodyrow">
                <div class="pair_table_bodyrow_top">
                  <div>
                    <img
                      src="assets/images/binance.svg"
                      alt="Binance"
                      title="Binance"
                    />
                    <h3>GFT<span>/ USDT</span></h3>
                  </div>
                </div>
                <div class="direction_badge_wrap"><small>0</small></div>
                <div class="pair_table_bodyrow_bottom">
                  <div class="pair_table_bodyrow_bottom_right">
                    <div>
                      <span>
                        <small class="redText d-flex price-status">
                          -67.603
                          <span class="pair_downarrow"
                            ><img
                              src="assets/images/downarrow.svg"
                              alt="down"
                              title="down"
                          /></span>
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="createbot_box">
    <div class="inputgrp_box">
      <div class="w-100 input-group">
        <label class="grey">Investment, USDT</label
        ><input
          aria-label="Username"
          aria-describedby="basic-addon1"
          name="investmentAmount"
          class="form-control"
          value="3894.11"
        /><span class="grey input-group-text" id="basic-addon1"
          >≈ 3893.72USD
        </span>
      </div>
    </div>
  </div>
  <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
  <div class="accr_box">
    <div class="accordion">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#manualAdjustment"
            aria-expanded="false"
            aria-controls="manualAdjustment"
          >
            Manual Adjustment
          </button>
        </h2>
        <div id="manualAdjustment" class="accordion-collapse collapse">
          <div class="accordion-body p-0">
            <div class="createbot_box mt-3">
              <div class="row">
                <div class="col-lg-6">
                  <div class="inputgrp_box">
                    <div class="w-100 input-group">
                      <label class="grey">Low price</label>
                      <input
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="investmentAmount"
                        class="form-control"
                        value="1.759"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="inputgrp_box">
                    <div class="w-100 input-group">
                      <label class="grey">High price</label>
                      <input
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="investmentAmount"
                        class="form-control"
                        value="3.187"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="inputgrp_box">
                    <div class="w-100 input-group">
                      <label class="grey">Grid step, %</label>
                      <input
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="investmentAmount"
                        class="form-control"
                        value="0.33"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="inputgrp_box">
                    <div class="w-100 input-group">
                      <label class="grey">Grid levels (2-180)</label>
                      <input
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="investmentAmount"
                        class="form-control"
                        value="175"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="boxFlexText mb-3">
                <p _ngcontent-ng-c446327758="" class="bot_preview_title mb-0">
                  <i
                    _ngcontent-ng-c446327758=""
                    class="bi bi-info-circle-fill ms-2"
                  ></i>
                  Stop Loss
                </p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    [(ngModel)]="isChecked"
                  />
                </div>
              </div>
              <div *ngIf="isChecked">
                <div class="inputgrp_box">
                  <div class="w-100 input-group">
                    <label class="grey"> price</label>
                    <input
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="investmentAmount"
                      class="form-control"
                      value="1.759"
                    />
                  </div>
                </div>
              </div>
              <div class="boxFlexText mb-3">
                <p _ngcontent-ng-c446327758="" class="bot_preview_title mb-0">
                  <i
                    _ngcontent-ng-c446327758=""
                    class="bi bi-info-circle-fill ms-2"
                  ></i>
                  Take Profit
                </p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    [(ngModel)]="isChecked2"
                  />
                </div>
              </div>
              <div *ngIf="isChecked2">
                <div class="inputgrp_box">
                  <div class="w-100 input-group">
                    <label class="grey"> Total PNl,5% </label>
                    <input
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="investmentAmount"
                      class="form-control"
                      value="5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="boxFlexText">
    <p class="bot_preview_title mb-0">
      <i class="bi bi-info-circle-fill me-2" data-tooltip-id="my-tooltip-6"></i
      >Available for bot use
    </p>
  </div>

  <div class="bot_preview_box mt-3">
    <div>
      <span>Quote currencyr</span><span class="border_line"></span
      ><span>47.8769638 USDT</span>
    </div>
    <div>
      <span>Base currency</span><span class="border_line"></span
      ><span>LDO</span>
    </div>
  </div>

  <div class="bgcolor"><button class="continue">Continue</button></div>
</div>
