<form [formGroup]="form">
    <!-- {{updateBuyForm | json}}
    {{updateSellForm | json}} -->
    <div class="sign_group sign_group_input">
        <label>Price</label>
        <div class="primary_inp_grp">
            <input type="text" class="sign_input" formControlName="price" decimals
                placeholder="{{toFixed(0, tradePair.secondFloatDigit)}}" [appDecimalLimit]="tradePair.secondFloatDigit"
                (input)="handleChange()" id="orderpriceid" />
            <div class="trade_buysell_control">
                <span>{{tradePair.secondCurrencySymbol}}</span>
                <div class="increament_btn_grp">
                    <button type="button" (click)="IncDec('price','dec')" [disabled]="!auth.signedIn">-</button>
                    <button type="button" (click)="IncDec('price','inc')" [disabled]="!auth.signedIn">+</button>
                </div>
            </div>
        </div>
        <div *ngIf="price?.invalid && (price?.touched || submitted)">
            <span class="required" *ngIf="price?.errors?.['required']">Price field is required</span>
        </div>
        <span class="required" *ngIf="serverErrors?.price">
            {{ serverErrors.price }}
        </span>
    </div>
    <div class="sign_group sign_group_input">
        <label>Amount</label>
        <div class="primary_inp_grp">
            <!-- <input type="text" class="sign_input" formControlName="quantity" (input)="handleChange()"
                placeholder="{{truncateDecimals(0, tradePair.firstFloatDigit)}}"
                [appDecimalLimit]="tradePair.firstFloatDigit" decimals /> -->
                <span>{{tradePair.firstCurrencySymbol}}</span>
                <input  (click)="checktds(tradePair.firstCurrencySymbol, tradePair.secondCurrencySymbol)"  type="text" class="sign_input" formControlName="quantity" (input)="handleChange()"
                placeholder="{{truncateDecimals(0, tradePair.firstFloatDigit)}}"
                [appDecimalLimit]="tradePair.firstFloatDigit" decimals />
            <div class="trade_buysell_control">
                <!-- <span>{{tradePair.firstCurrencySymbol}}</span> -->
                <div class="increament_btn_grp">
                    <button type="button" (click)="IncDec('quantity','dec')" [disabled]="!auth.signedIn">-</button>
                    <button type="button" (click)="IncDec('quantity','inc')" [disabled]="!auth.signedIn">+</button>
                </div>
            </div>
        </div>
        <div *ngIf="quantity?.invalid && (quantity?.touched || submitted)">
            <span class="required" *ngIf="quantity?.errors?.['required']">Quantity field is required</span>
        </div>
        <span class="required" *ngIf="serverErrors?.quantity">
            {{ serverErrors.quantity }}
        </span>
    </div>
    <!-- <ul class="buypercent"> -->
    <ul class="buypercent"  (click)="checktds(tradePair.firstCurrencySymbol, tradePair.secondCurrencySymbol)">    
        <li (click)="handleSetPercentage(0)">
            <button type="button" [ngClass]="{'active': buyPercentage === 0}" [disabled]="!auth.signedIn">0%</button>
        </li>
        <li (click)="handleSetPercentage(25)">
            <button type="button" [ngClass]="{'active': buyPercentage === 25}" [disabled]="!auth.signedIn">25%</button>
        </li>
        <li (click)="handleSetPercentage(50)">
            <button type="button" [ngClass]="{'active': buyPercentage === 50}" [disabled]="!auth.signedIn">50%</button>
        </li>
        <li (click)="handleSetPercentage(75)">
            <button type="button" [ngClass]="{'active': buyPercentage === 75}" [disabled]="!auth.signedIn">75%</button>
        </li>
        <li (click)="handleSetPercentage(100)">
            <button type="button" [ngClass]="{'active': buyPercentage === 100}"
                [disabled]="!auth.signedIn">100%</button>
        </li>
    </ul>
    <div class="sign_group sign_group_input">
        <label>Total</label>
        <div class="primary_inp_grp">
            <input type="text" class="sign_input" formControlName="total" (input)="handleChangeTotal()"
                placeholder="{{truncateDecimals(0, tradePair.secondFloatDigit)}}"
                [appDecimalLimit]="tradePair.secondFloatDigit" decimals />
            <div class="trade_buysell_control">
                <span>{{tradePair.secondCurrencySymbol}}</span>
            </div>
        </div>
    </div>
    <button type='button' class="primary_btn w-100" *ngIf="!auth.signedIn; else submitbutton"
        (click)="navigateToLogin()">
        LOGIN
    </button>
    <ng-template #submitbutton>
        <button type="button" [ngClass]="{'trade_buy_btn': buyorsell === 'buy', 'trade_sell_btn': buyorsell === 'sell'}"
            (click)="submitForm()">
            {{buyorsell | titlecase}} <span>{{tradePair.firstCurrencySymbol}}</span>
        </button>
    </ng-template>
    <div>
        <span *ngIf="tdsapply">1% TDS charge will be deducted</span>
    </div>
</form>