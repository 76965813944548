<div class="subscription-main">
    <app-header></app-header>
    <div class="inner_cms_layout">
        <div class="container">
            <div class="subscription-fixed-width">
                <div class="subscription-head">
                    <h4>Plans & Pricing</h4>
                    <h6>
                        All CredExchange pricing plans come with a 7‑day free trial on the
                        PRO plan. No credit card required.
                    </h6>
                </div>
                <ul class="nav nav-tabs subscription-tab" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" *ngFor="let item of plan">
                        <button class="nav-link" id="{{ item.plan }}-tab" data-bs-toggle="tab"
                            [attr.data-bs-target]="'#' + item.plan" type="button" role="tab"
                            [attr.aria-controls]="item.plan"
                            [attr.aria-selected]="actTab === item.plan ? 'true' : 'false'"
                            [ngClass]="{ 'active': actTab === item.plan }" (click)="setActTab(item.plan)">
                            {{ item?.value }}
                        </button>
                    </li>
                    <!-- <li class="nav-item" role="presentation" >
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true">
                            12 months
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false">
                            1 months
                        </button>
                    </li> -->
                </ul>

                <div class="tab-content subscription-tabcontent">
                    <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="subscription-box-head">
                            <div class="subscription-box" *ngFor="let item of subsData">
                                <div>
                                    <div class="subscription-title">
                                        <h6>{{firstCapital(item.title)}}</h6>
                                        <p>{{ item.title === 'basic' ? 'Essential features for aspiring traders' :
                                            item.title === 'advanced' ? 'A full-featured suite for seasoned traders' :
                                            'Maximum performance and versatility for expert traders' }}</p>
                                        <h5>${{item.amount}} <span>/ {{ item.planType }} month</span></h5>
                                    </div>
                                    <div class="subscription-card">
                                        <p>
                                            <img src="../../../assets/images/grid-bot.png" alt="" img="img-fluid"
                                                class="me-2" />{{item.gridCount}} Active Grid bots
                                        </p>
                                        <p>
                                            <img src="../../../assets/images/trade-bot.png" alt="" img="img-fluid"
                                                class="me-2" />{{item.dcaCount}} Active DCA bots
                                        </p>
                                    </div>
                                    <div class="subscription-list">
                                        <ul>
                                            <li>Unlimited Manual trading</li>
                                            <li>Unlimited Smart orders</li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="subscription-box-button">
                                    <button (click)="submitForm(item)">Start Now</button>
                                </div>
                                <div class="subscription-box-button yellow-btn">
                                    <button [disabled]="true" >
                                        Purchased </button>
                                </div>
                                <div class="subscription-box-button blue-btn">
                                    <button [disabled]="true">Upgrade Now</button>
                                </div>
                                <div class="highlight-text">
                                    <p>You already have a higher subscription</p>
                                </div> -->
                                <!-- <div *ngIf="purchaseStatus == 'not_purchased'" class="subscription-box-button">
                                    <button (click)="submitForm(item)">Start Now</button>
                                </div> -->
                                <div *ngIf="purchaseStatus == 'not_purchased'" class="subscription-box-button "
                                    data-bs-toggle="modal" data-bs-target="#confirm_enable_modal">
                                    <button (click)="setData(item)"> Start Now</button>
                                </div>
                                <div *ngIf="purchaseStatus === 'purchased' && item._id === purchaseDet?.planId"
                                    class="subscription-box-button yellow-btn">
                                    <button [disabled]="true">
                                        Purchased </button>
                                </div>
                                <!-- <div *ngIf="purchaseStatus === 'purchased' && parseAmount(item.amount) > parseAmount(purchaseDet?.amount)"
                                    class="subscription-box-button blue-btn">
                                    <button (click)="upgradeForm(item)">Upgrade Now</button>
                                </div> -->
                                <div *ngIf="purchaseStatus === 'purchased' && parseAmount(item.amount) > parseAmount(purchaseDet?.amount)"
                                    class="subscription-box-button blue-btn" data-bs-toggle="modal"
                                    data-bs-target="#upgrade_enable_modal">
                                    <button (click)="setData(item)"> Upgrade Now</button>
                                </div>
                                <div *ngIf="purchaseStatus === 'purchased' && parseAmount(purchaseDet?.amount) >
                                    parseAmount(item.amount)" class="highlight-text">
                                    <p>You already have a higher subscription</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade primary_modal" id="upgrade_enable_modal" tabindex="-1"
        aria-labelledby="upgrade_enable_modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="upgrade_enable_modalLabel">
                        Confirm Purchase
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Are you sure to upgrade this plan</label>
                    </div>
                    <div>
                        <button class="trade_buy_btn my-2" type="button" (click)="upgradeForm()">
                            Confirm
                        </button>
                        <button class="trade_sell_btn" type="button" id="upgradeOrderModalBtn" data-bs-dismiss="modal"
                            aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade primary_modal" id="confirm_enable_modal" tabindex="-1"
        aria-labelledby="confirm_enable_modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="confirm_enable_modalLabel">
                        Confirm Purchase
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Are you sure to purchase this plan</label>
                    </div>
                    <div>
                        <button class="trade_buy_btn my-2" type="button" (click)="submitForm()">
                            Confirm
                        </button>
                        <button class="trade_sell_btn" type="button" id="startOrderModalBtn" data-bs-dismiss="modal"
                            aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>