import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { botSocket } from 'src/app/app.module';
import { dateHours } from 'src/app/lib/dateFilter';
import isEmpty from 'src/app/lib/isEmpty';
import { toFixedDown } from 'src/app/lib/roundOf';
import { BotService } from 'src/app/services/bot.service';
import { PairFacade } from 'src/app/store/pair';
import { PositionDetailsFacade } from 'src/app/store/positionDetails';
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-dcaorderhistory',
  templateUrl: './dcaorderhistory.component.html',
  styleUrls: ['./dcaorderhistory.component.css']
})
export class DcaorderhistoryComponent {
  pairData: any = {}
  pairs: any = []
  sumProfit: any = { sumpnl: 0, sumpnlval: 0 }
  totCurrent: any = {}
  data: any = []
  constructor(
    private service: BotService,
    private toastr: ToastrService,
    private botFacade: TradePairFacade,
    private pairFacade: PairFacade,
    private positionDetails: PositionDetailsFacade,
    private botSocket: botSocket
  ) {

  }

  ngOnInit() {
    this.getSinglePair()
    this.getPairs()
    this.botSocket.on('runningOrder', this.fetchDetailsWS)
  }
  getPairs() {
    this.pairFacade.pair$.subscribe({
      next: (pair: any) => {
        this.pairs = pair
      },
      error: (err: any) => { }
    })
  }
  getSinglePair() {
    this.botFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.pairData = pair
        this.fetchDetails()
      },
      error: (err: any) => { }
    })
  }
  calPrice(priceData: any, type: any) {
    try {
      let total: any = 0,
        totalQty = 0,
        totalPrice = 0;
      if (type == "price") {
        for (let data of priceData) {
          total = total + data.price;
        }
        if (total > 0) {
          return total;
        }
      }
      if (type == "quantity") {
        for (let data of priceData) {
          total = total + data.quantity;
        }
        if (total > 0) {
          return parseFloat(total).toFixed(6);
        }
      }
      if (type == "checksell") {
        for (let data of priceData) {
          total = total + data.quantity * data.price;
          totalQty = totalQty + data.quantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      if (type == "Totalprice") {
        for (let data of priceData) {
          totalPrice = totalPrice + data.orgquantity * data.price;
        }
        return totalPrice;
      }

      if (type == "TotalpriceBM") {
        for (let data of priceData) {
          totalPrice =
            totalPrice + data.orgquantity * data.price + data.comissionFee;
        }
        return totalPrice;
      }

      if (type == "orgquantity") {
        for (let data of priceData) {
          total = total + data.orgquantity;
        }
        if (total > 0) {
          return parseFloat(total);
        }
      }

      if (type == "avgorgqty") {
        for (let data of priceData) {
          total = total + data.orgquantity * data.price;
          totalQty = totalQty + data.orgquantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      return 0;
    } catch (err) {
      console.log(err, "----3333");

      return 0;
    }
  };
  fetchDetails() {
    console.log(this.pairData, '-----35')
    let reqData = {
      botType: "GENIE",
      exchange: this.pairData?.botstatus,
      pair: this.pairData?.tikerRoot,
    };
    this.service.getOpenOrders(reqData).subscribe({
      next: (v: any) => {
        let pairList = []
        console.log(v?.result, '------42')
        for (let [index, item] of v.result.entries()) {
          let avgPrice = item.avgPrice / item.quantity;
          let priceDecimal = item.priceDecimal;
          let finalQty = this.calPrice(item.filled, "quantity");
          let totalOrgQty = this.calPrice(item.filled, "orgquantity");
          let combuybnbUsdt = 0;
          finalQty = toFixedDown(finalQty, item?.quantityDecimal);

          // console.log(finalQty, "------finalQty");
          let investmentPrice = this.calPrice(item.filled, "Totalprice");
          if (item.exchange == "BitMart") {
            investmentPrice = this.calPrice(item.filled, "TotalpriceBM");
          }
          if (this.pairData._id.toString() == item.pairId.toString()) {
            this.positionDetails.setPosition(item)
          }
          for (let itemfill of item?.filled) {
            if (
              !isEmpty(itemfill?.feeFilled) &&
              itemfill?.feeFilled.length > 0
            ) {
              for (let buyfeeItem of itemfill?.feeFilled) {
                if (
                  buyfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                }
              }
            }
          }

          let singlePair = this.pairs.find(
            (el: any) => el._id.toString() == item.pairId.toString()
          );
          console.log(singlePair, "-----singlePairsinglePair");

          let exovaluewfee;

          exovaluewfee = singlePair.markPrice * finalQty;

          exovaluewfee = exovaluewfee - combuybnbUsdt;
          let finalProfit = exovaluewfee - investmentPrice;
          finalProfit = toFixedDown(finalProfit, priceDecimal);
          let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

          console.log(finalQty, "his1");
          console.log(totalOrgQty, "his3");
          console.log(exovaluewfee, "his3");
          console.log(singlePair.markPrice, "his4");
          console.log(finalProfit, "his5");
          console.log(investmentPrice, "his6");
          console.log(combuybnbUsdt, "his7");

          let investment = 0;
          for (let data of item.entryPrice) {
            if (item.exchange == "BitMart") {
              investment =
                investment + data.orgquantity * data.price + data.comissionFee;
            } else {
              investment = investment + data.orgquantity * data.price;
            }
          }

          let cmp1 = singlePair.markPrice * item.quantity;

          let cmp =
            singlePair.markPrice * item.quantity - avgPrice * item.quantity;

          // console.log(investment, "investment----");
          // console.log(finalProfit, "finalProfit----");
          let curVal = investment + finalProfit;
          // console.log(curVal, "-------641");
          let txnDate = dateHours(item.createdAt)
          pairList.push({
            ...item,
            currentvalue: toFixedDown(curVal, item?.quantityDecimal),
            cmp: singlePair.markPrice,
            cmp1: cmp1,
            // investment: investment ? toFixed(investment, 4) : 0.0,
            investment: investment ? toFixedDown(investment, item?.quantityDecimal) : 0.0,
            pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
            pnlval: finalProfit,
            txnDate: txnDate,
            // profitinprice: profitinprice,
            referralCode: item.referralCode,
            takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
            index: index,
          });
        }
        const sumOfFields = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.currentvalue);
        }, 0);

        const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pl);
        }, 0);

        const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pnlval);
        }, 0);

        this.totCurrent = sumOfFields;
        this.sumProfit = {
          sumpnl: sumOfFields_pnl,
          sumpnlval: sumOfFields_pnl_val,
        };

        this.data = pairList;

      },
      error: (err: any) => {
        if ([400, 404].includes(err.status)) {
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  fetchDetailsWS(result: any) {
    console.log(result, '---------260')
    // let pairList = []
    // console.log(result.data, '------42')
    // for (let [index, item] of result.data.entries()) {
    //   let avgPrice = item.avgPrice / item.quantity;
    //   let priceDecimal = item.priceDecimal;
    //   let finalQty = this.calPrice(item.filled, "quantity");
    //   let totalOrgQty = this.calPrice(item.filled, "orgquantity");
    //   let combuybnbUsdt = 0;
    //   finalQty = toFixedDown(finalQty, item?.quantityDecimal);

    //   // console.log(finalQty, "------finalQty");
    //   let investmentPrice = this.calPrice(item.filled, "Totalprice");
    //   if (item.exchange == "BitMart") {
    //     investmentPrice = this.calPrice(item.filled, "TotalpriceBM");
    //   }
    //   if (this.pairData._id.toString() == item.pairId.toString()) {
    //     this.positionDetails.setPosition(item)
    //   }
    //   for (let itemfill of item?.filled) {
    //     if (
    //       !isEmpty(itemfill?.feeFilled) &&
    //       itemfill?.feeFilled.length > 0
    //     ) {
    //       for (let buyfeeItem of itemfill?.feeFilled) {
    //         if (
    //           buyfeeItem?.commissionAsset == "BNB" &&
    //           item.exchange == "Binance"
    //         ) {
    //           combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
    //         }
    //       }
    //     }
    //   }

    //   let singlePair = this.pairs.find(
    //     (el: any) => el._id.toString() == item.pairId.toString()
    //   );
    //   console.log(singlePair, "-----singlePairsinglePair");
    //   let exovaluewfee;
    //   exovaluewfee = singlePair.markPrice * finalQty;
    //   exovaluewfee = exovaluewfee - combuybnbUsdt;
    //   let finalProfit = exovaluewfee - investmentPrice;
    //   finalProfit = toFixedDown(finalProfit, priceDecimal);
    //   let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

    //   let investment = 0;
    //   for (let data of item.entryPrice) {
    //     if (item.exchange == "BitMart") {
    //       investment =
    //         investment + data.orgquantity * data.price + data.comissionFee;
    //     } else {
    //       investment = investment + data.orgquantity * data.price;
    //     }
    //   }

    //   let cmp1 = singlePair.markPrice * item.quantity;

    //   let cmp =
    //     singlePair.markPrice * item.quantity - avgPrice * item.quantity;

    //   // console.log(investment, "investment----");
    //   // console.log(finalProfit, "finalProfit----");
    //   let curVal = investment + finalProfit;
    //   // console.log(curVal, "-------641");
    //   let txnDate = dateHours(item.createdAt)
    //   pairList.push({
    //     ...item,
    //     currentvalue: toFixedDown(curVal, item?.quantityDecimal),
    //     cmp: singlePair.markPrice,
    //     cmp1: cmp1,
    //     // investment: investment ? toFixed(investment, 4) : 0.0,
    //     investment: investment ? toFixedDown(investment, item?.quantityDecimal) : 0.0,
    //     pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
    //     pnlval: finalProfit,
    //     txnDate: txnDate,
    //     // profitinprice: profitinprice,
    //     referralCode: item.referralCode,
    //     takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
    //     index: index,
    //   });
    // }
    // const sumOfFields = pairList.reduce((sum, currentField) => {
    //   return sum + parseFloat(currentField.currentvalue);
    // }, 0);

    // const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
    //   return sum + parseFloat(currentField.pl);
    // }, 0);

    // const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
    //   return sum + parseFloat(currentField.pnlval);
    // }, 0);

    // this.totCurrent = sumOfFields;
    // this.sumProfit = {
    //   sumpnl: sumOfFields_pnl,
    //   sumpnlval: sumOfFields_pnl_val,
    // };

    // this.data = pairList;

  }
}
