import { Component } from '@angular/core';

@Component({
  selector: 'app-dcabotstop',
  templateUrl: './dcabotstop.component.html',
  styleUrls: ['./dcabotstop.component.css']
})
export class DcabotstopComponent {

}
