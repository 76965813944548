import { Component, Renderer2, inject, ElementRef } from '@angular/core';
import { Cookies } from 'src/app/lib/cookies';
import { AuthService } from 'src/app/services/auth.service';
import { UserFacade } from '../../store/user';
import { UserSettingFacade } from 'src/app/store/usersetting';
interface Profile {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-header-bot',
  templateUrl: './header-bot.component.html',
  styleUrls: ['./header-bot.component.css']
})
export class HeaderBotComponent {
profileDetails: Profile = {
    firstName: '',
    lastName: '',
    email: ''
  };
  theme = ''

  loggedIn: boolean = false
  private readonly userFacade: UserFacade = inject(UserFacade);
  constructor(
    private cookie: Cookies,
    private renderer: Renderer2,
    private service: AuthService,
    private elementRef: ElementRef,
    private userSettingFacade: UserSettingFacade
  ) {
    if (this.cookie.isLogin()) {
      this.loggedIn = true
    }
    this.getprofile()
  }

  ngOnInit() {
    this.getUserSetting()
  }

  onClickToggleClass() {
    this.renderer.addClass(document.body, 'light_theme');
  }

  signOut(): void {
    this.service.logoutApi()
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.profileDetails.email = data.email
          this.profileDetails.firstName = data.firstName
          this.profileDetails.lastName = data.lastName
        },

        complete: () => { }
      })
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }

  ngAfterViewInit() {
    // Use jQuery to handle the click event
    $(this.elementRef.nativeElement).find('.header__btn').click(() => {
      // console.log('Button clicked using jQuery!');
      $(this.elementRef.nativeElement).toggleClass("header__btn--active");
      $(".header").toggleClass("header--active");
      $(".sidebar").toggleClass("sidebar--active");
      $(".home_menu_bar").toggleClass("sidebar--active");
    });
  }

}
