import { Component } from '@angular/core';

@Component({
  selector: 'app-gridbotpair',
  templateUrl: './gridbotpair.component.html',
  styleUrls: ['./gridbotpair.component.css']
})
export class GridbotpairComponent {

}
